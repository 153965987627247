import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import Loading from '../Loading';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Paper, Button, Alert, TextField, TableSortLabel
} from '@mui/material';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip as RechartsTooltip, Legend, ResponsiveContainer } from 'recharts';

const AccessLog = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [accessData, setAccessData] = useState(null);
  const [uniqueUserCounts, setUniqueUserCounts] = useState([]);
  const [groupBy, setGroupBy] = useState('day');
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertSeverity, setAlertSeverity] = useState('success');
  const [sortBy, setSortBy] = useState('timestamp');
  const [sortOrder, setSortOrder] = useState('desc');
  const [search, setSearch] = useState('');

  const getToken = () => localStorage.getItem('token');

  const fetchAccessData = useCallback((groupBy, sortBy, sortOrder, search) => {
    const token = getToken();
    axios.get(`${apiUrl}/users/admin/access_metrics/?group_by=${groupBy}&period=last_30_days`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(response => setAccessData(response.data))
      .catch(error => {
        console.error('Error fetching access metrics:', error);
        setAlertMessage('Error fetching access metrics');
        setAlertSeverity('error');
      });

    axios.get(`${apiUrl}/users/admin/unique_user_counts`, {
      headers: {
        Authorization: `Bearer ${token}`,
        params: {
          sort_by: sortBy,
          sort_order: sortOrder,
          search: search
        }
      }
    })
      .then(response => setUniqueUserCounts(response.data.unique_user_counts))
      .catch(error => {
        console.error('Error fetching unique user counts:', error);
        setAlertMessage('Error fetching unique user counts');
        setAlertSeverity('error');
      });
  }, [apiUrl]);

  useEffect(() => {
    fetchAccessData(groupBy, sortBy, sortOrder, search);
  }, [groupBy, sortBy, sortOrder, search, fetchAccessData]);

  if (!accessData) {
    return <Loading />;
  }

  const {
    user_today_access,
    user_total_access,
    user_max_access,
    user_min_access,
    user_mean_access,
    guest_today_access,
    guest_total_access,
    guest_max_access,
    guest_min_access,
    guest_mean_access,
    total_today_access,
    total_total_access,
    total_max_access,
    total_min_access,
    total_mean_access,
    user_logs,
    guest_logs
  } = accessData;

  const combinedLogs = user_logs.map((log, index) => ({
    date: log.date,
    user_count: log.count,
    guest_count: guest_logs[index]?.count || 0
  }));

  const handleSort = (column) => {
    const isAsc = sortBy === column && sortOrder === 'asc';
    setSortOrder(isAsc ? 'desc' : 'asc');
    setSortBy(column);
  };

  return (
    <div style={{ padding: '2rem' }}>
      <h1>Access Log Management</h1>
      {alertMessage && <Alert style={{ marginBottom: '10px' }} severity={alertSeverity}>{alertMessage}</Alert>}
      <div style={{ marginBottom: '1rem' }}>
        <Button variant="contained" color="primary" onClick={() => setGroupBy('day')} disabled={groupBy === 'day'} style={{ marginRight: '10px' }}>
          Group by Day
        </Button>
        <Button variant="contained" color="secondary" onClick={() => setGroupBy('month')} disabled={groupBy === 'month'}>
          Group by Month
        </Button>
      </div>

      <h3>Access Logs Counts</h3> 
      <TableContainer component={Paper} style={{ marginTop: '1rem', width: '100%', overflowX: 'auto' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Metric</TableCell>
              <TableCell>User</TableCell>
              <TableCell>Guest</TableCell>
              <TableCell>Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Today's Access</TableCell>
              <TableCell>{user_today_access}</TableCell>
              <TableCell>{guest_today_access}</TableCell>
              <TableCell>{total_today_access}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Total Access</TableCell>
              <TableCell>{user_total_access}</TableCell>
              <TableCell>{guest_total_access}</TableCell>
              <TableCell>{total_total_access}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Max Access</TableCell>
              <TableCell>{user_max_access}</TableCell>
              <TableCell>{guest_max_access}</TableCell>
              <TableCell>{total_max_access}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Min Access</TableCell>
              <TableCell>{user_min_access}</TableCell>
              <TableCell>{guest_min_access}</TableCell>
              <TableCell>{total_min_access}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Mean Access</TableCell>
              <TableCell>{Math.round(user_mean_access)}</TableCell>
              <TableCell>{Math.round(guest_mean_access)}</TableCell>
              <TableCell>{Math.round(total_mean_access)}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <h3>Access Logs Over Time</h3>
      <ResponsiveContainer width="100%" height={400}>
        <LineChart data={combinedLogs}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis />
          <RechartsTooltip />
          <Legend />
          <Line type="monotone" dataKey="user_count" stroke="#8884d8" activeDot={{ r: 8 }} />
          <Line type="monotone" dataKey="guest_count" stroke="#82ca9d" />
        </LineChart>
      </ResponsiveContainer>

      <TextField
        label="Search"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        fullWidth
        style={{ marginBottom: '1rem' }}
      />

      <h3>Unique User Counts</h3>
      <TableContainer component={Paper} style={{ marginTop: '1rem', width: '100%', overflowX: 'auto' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={sortBy === 'timestamp'}
                  direction={sortOrder}
                  onClick={() => handleSort('timestamp')}
                >
                  Date
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortBy === 'user_count'}
                  direction={sortOrder}
                  onClick={() => handleSort('user_count')}
                >
                  User Count
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortBy === 'guest_user_count'}
                  direction={sortOrder}
                  onClick={() => handleSort('guest_user_count')}
                >
                  Guest User Count
                </TableSortLabel>
              </TableCell>
              <TableCell>User IDs</TableCell>
              <TableCell>Guest User IDs</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {uniqueUserCounts.map((record) => (
              <TableRow key={record.id}>
                <TableCell>{new Date(record.timestamp).toLocaleDateString()}</TableCell>
                <TableCell>{record.user_count}</TableCell>
                <TableCell>{record.guest_user_count}</TableCell>
                <TableCell>{record.user_ids.join(', ')}</TableCell>
                <TableCell>{record.guest_user_ids.join(', ')}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <h3>Unique User Counts Over Time</h3>
      <ResponsiveContainer width="100%" height={400}>
        <LineChart data={uniqueUserCounts.map(record => ({
          date: new Date(record.timestamp).toLocaleDateString(),
          user_count: record.user_count,
          guest_user_count: record.guest_user_count
        }))}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis />
          <RechartsTooltip />
          <Legend />
          <Line type="monotone" dataKey="user_count" stroke="#8884d8" activeDot={{ r: 8 }} />
          <Line type="monotone" dataKey="guest_user_count" stroke="#82ca9d" />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default AccessLog;