import React, { useState, useEffect } from 'react';
import { Button, Box, Typography, Modal, Alert, IconButton, Stepper, Step, StepLabel, useMediaQuery } from '@mui/material';
import { styled, useTheme } from '@mui/system';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DownloadIcon from '@mui/icons-material/Download';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export const Container = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  padding: theme.spacing(1),
  maxWidth: 1500,
  margin: '0 auto',
  backgroundColor: theme.palette.background.default,
  color: theme.palette.text.primary,
}));

export const ButtonsContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  gap: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

export const StyledModalBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  borderRadius: '8px',
  boxShadow: theme.shadows[2],
  maxWidth: 400,
  width: '100%', // Ensure the modal doesn't exceed the screen width
}));

export const ModalButtonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  marginTop: theme.spacing(2),
  gap: theme.spacing(2), // Add spacing between buttons
}));

const UploadButton = ({ onClick, disabled, label }) => (
  <Button
    variant="contained"
    color="primary"
    startIcon={<CloudUploadIcon />}
    onClick={onClick}
    disabled={disabled}
    sx={{
      padding: '20px 10px',
      marginTop: '10px',
      marginBottom: '10px',
      position: 'relative',
      width: '250px',
      height: '50px',
      fontSize: { xs: '0.875rem', sm: '1rem', md: '1rem', lg: '1rem', xl: '1rem' }, // Adjust font size based on screen width
    }}
  >
    {label}
  </Button>
);

const ProcessButton = ({ onClick, disabled, label }) => (
  <Button
    variant="contained"
    color="primary"
    startIcon={<AutorenewIcon />}
    onClick={onClick}
    disabled={disabled}
    sx={{
      padding: '20px 10px',
      marginTop: '10px',
      marginBottom: '10px',
      position: 'relative',
      width: '250px',
      height: '50px',
      fontSize: { xs: '0.875rem', sm: '1rem', md: '1rem', lg: '1rem', xl: '1rem' }, // Adjust font size based on screen width
    }}
  >
    {label}
  </Button>
);

const DownloadButton = ({ onClick, disabled, label }) => (
  <Button
    variant="contained"
    color="secondary"
    startIcon={<DownloadIcon />}
    onClick={onClick}
    disabled={disabled}
    sx={{
      padding: '20px 10px',
      marginTop: '10px',
      marginBottom: '10px',
      position: 'relative',
      width: '250px',
      height: '50px',
      fontSize: { xs: '0.875rem', sm: '1rem', md: '1rem', lg: '1rem', xl: '1rem' }, // Adjust font size based on screen width
    }}
  >
    {label}
  </Button>
);

const ModalWindow = ({ open, handleClose, handleConfirm, title, description, confirmText, cancelText }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <StyledModalBox>
        <Typography id="modal-title" variant="h6" component="h2" gutterBottom>
          {title}
        </Typography>
        {description && (
          <Typography id="modal-description" sx={{ mb: 2 }}>
            {description}
          </Typography>
        )}
        <ModalButtonContainer>
          <Button variant="outlined" color="secondary" onClick={handleClose}>
            {cancelText}
          </Button>
          <Button variant="contained" color="primary" onClick={handleConfirm}>
            {confirmText}
          </Button>
        </ModalButtonContainer>
      </StyledModalBox>
    </Modal>
  );
};

const RemoveButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(1),
  right: theme.spacing(1),
  backgroundColor: theme.palette.background.paper,
  zIndex: 1000, // Ensure it is above other elements
  '&:hover': {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.white,
  },
}));

const SmoothAlert = ({ message, severity, onClose }) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (message) {
      setVisible(true);
      setTimeout(() => {
        setVisible(false);
        setTimeout(onClose, 300); // Allow the fade-out animation to complete before calling onClose
      }, 3000); // Keep the message visible for 3 seconds
    }
  }, [message, onClose]);

  return (
    <Box
      sx={{
        opacity: visible ? 1 : 0,
        transition: 'opacity 0.5s ease-in-out', // Smooth fade effect
        pointerEvents: visible ? 'auto' : 'none', // Prevent interaction while fading out
      }}
    >
      <Alert severity={severity} onClose={onClose}>
        {message}
      </Alert>
    </Box>
  );
};

// StepIcon Component
const icons = {
  1: CloudUploadIcon,
  2: AutorenewIcon,
  3: DownloadIcon,
};

// Add spinning animation
const SpinningIcon = styled('div')(({ theme, isSpinning }) => ({
  animation: isSpinning ? 'spin 2s linear infinite' : 'none',
  '@keyframes spin': {
    '0%': { transform: 'rotate(0deg)' },
    '100%': { transform: 'rotate(360deg)' }
  },
  display: 'inline-flex'
}));

const StepIcon = (props) => {
  const { active, completed, icon } = props;
  const IconComponent = icons[icon];

  if (completed) {
    return <CheckCircleIcon color="success" />;
  }

  if (IconComponent === AutorenewIcon && active) {
    return (
      <SpinningIcon isSpinning={true}>
        <IconComponent color="primary" />
      </SpinningIcon>
    );
  }

  return <IconComponent color={active ? 'primary' : 'disabled'} />;
};

// StepComponent
const StepComponent = ({ activeStep, t }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const STEPS = [
    { label: t.upload, icon: CloudUploadIcon },
    { label: t.process, icon: AutorenewIcon },
    { label: t.download, icon: DownloadIcon }
  ];

  return (
    <Stepper activeStep={activeStep} sx={{ mb: 4 }}>
      {STEPS.map((step, index) => (
        <Step key={index} completed={index < activeStep}>
          <StepLabel
            StepIconComponent={(props) => <StepIcon {...props} icon={index + 1} />}
            sx={{
              '& .MuiStepLabel-label': {
                fontSize: { xs: '0.875rem', sm: '1rem', md: '1rem', lg: '1rem', xl: '1rem' }, // Adjust sizes based on screen width
                display: isMobile ? 'none' : 'block' // Hide text on mobile
              },
            }}
          >
            {step.label}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

export { UploadButton, ProcessButton, DownloadButton, ModalWindow, SmoothAlert, RemoveButton, StepComponent };