import React, { createContext, useMemo, useContext } from "react";
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import { lightTheme, darkTheme } from "./themes";
import { AuthContext } from './AuthContext';

const ThemeModeContext = createContext();

export const ThemeModeProvider = ({ children }) => {
  const { theme, setTheme } = useContext(AuthContext);
  const toggleTheme = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    setTheme(newTheme);
  };

  const themeObject = useMemo(
    () => (theme === "light" ? lightTheme : darkTheme), 
    [theme]
  );

  return (
    <ThemeModeContext.Provider value={{ theme, toggleTheme }}>
      <ThemeProvider theme={themeObject}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </ThemeModeContext.Provider>
  );
};

export const useThemeMode = () => useContext(ThemeModeContext);