import React from 'react';
import { Box, Typography, Paper } from '@mui/material';
import { styled } from '@mui/system';

const TitleBox = styled(Box)(({ theme }) => ({
  textAlign: "center",
  marginBottom: theme.spacing(4)
}));

const SectionContainer = styled(Paper)(({ theme }) => ({
  position: 'relative',
  overflow: 'hidden', // Prevent image overflow
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(4),
}));

const TopPageSection = ({ title, description, children }) => (
  <SectionContainer variant="section2">
    <TitleBox>
      <Typography
        variant="h3"
        gutterBottom
        sx={{
          fontWeight: "bold",
          color: "text.main",
          fontSize: { xs: "2rem", sm: "2.5rem", md: "3rem" },
        }}
      >
        {title}
      </Typography>
      <Typography
        variant="body1"
        sx={{
          color: "text.secondary",
          marginBottom: 2,
          fontSize: { xs: "0.9rem", sm: "1rem" },
        }}
      >
        {description}
      </Typography>
    </TitleBox>
    {children}
  </SectionContainer>
);

export default TopPageSection;