import { styled } from '@mui/system';
import { Button, Box } from '@mui/material';

// Styled Components
export const HeaderDesktopNav = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexGrow: 1,
}));

export const HeaderNavItem = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
}));

export const HeaderButton = styled(Button)(({ theme }) => ({
    color: '#E5A0FF', // Match button text color
    border: '2px solid transparent',
    transition: 'border-color 0.3s ease, color 0.3s ease',
    backgroundColor: '#4A0072', // Deep Purple for consistency
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', // Shadow for luxury effect
    margin: '0 5px',
    '&:hover, &.with-border': {
        fontWeight: 'bold', // Add bold font to buttons
        border: '2px solid #E5A0FF', // Consistent border color on hover
        borderRadius: '4px', // Adjust the border radius if needed
        backgroundColor: '#9C44AA', // Bright Purple on hover
    },
}));


export const UserInfo = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));