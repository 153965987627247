import React, { useState, useContext, useRef } from 'react';
import { Box, Typography, Alert, TextField, LinearProgress, IconButton } from '@mui/material';
import { styled } from '@mui/system';
import { AuthContext } from '../contexts/AuthContext';
import { UserContext } from '../contexts/UserContext';
import { useTranslations } from '../contexts/TranslationsContext';
import { ServicesContext } from '../contexts/ServicesContext';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckIcon from '@mui/icons-material/Check';
import { Container, ButtonsContainer, UploadButton, ProcessButton, ModalWindow } from './ReusableComponents';
import { StepComponent } from './ReusableComponents';
import ImageUploadFrame from './ImageServiceComponents/ImageUploadFrame';
import ImageCard from './ImageServiceComponents/ImageCard';
import { handleAPIError } from './utils/ErrorHandler';
import { updateUserData } from './utils/userUtils';
import { createApi } from './utils/api';

const TextFieldStyled = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  '& .MuiInputBase-root': {
    fontSize: '1rem',
    padding: theme.spacing(2),
  },
}));


const TextToImageAI = ({ language }) => {
  const { translations: t } = useTranslations();
  const { isAuthenticated } = useContext(AuthContext);
  const { setUser } = useContext(UserContext);
  const { services } = useContext(ServicesContext);
  const service = services.find((service) => service.url === '/text-to-image-generator');

  const [modalOpen, setModalOpen] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [prompt, setPrompt] = useState('');
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [uploadProgress, setUploadProgress] = useState(0);
  const [downloadProgress, setDownloadProgress] = useState(0);
  const [processProgress, setProcessProgress] = useState(null);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [copied, setCopied] = useState(false);

  const inputRef = useRef(null);

  const apiUrl = process.env.REACT_APP_API_URL;

  const api = React.useMemo(() => createApi(apiUrl, isAuthenticated, setDownloadProgress), [apiUrl, isAuthenticated]);
  
  const handleProcessClick = () => {
    setModalOpen(true);
  };

  const handleConfirmProcess = () => {
    setModalOpen(false);
    handleGenerate();
  };

  const handleGenerate = async () => {
    if (!prompt) {
      setError(t.errorPromptFill);
      return;
    }
    
    setLoading(true);
    setError('');
    
    try {
      const { data } = await api.generateImage(prompt, uploadedImage, setUploadProgress, service.url);
      setActiveStep(1);

      await pollStatus(data.file_id);

    } catch (error) {
      handleAPIError({
        error: error,
        setError: setError,
        setLoading: setLoading,
        setSuccess: setSuccess,
        translations: t
      });
    }
  };

  const pollStatus = async (fileId) => {
    const interval = setInterval(async () => {
      try {
        const { data } = await api.checkStatus(fileId, service.url);
        setProcessProgress(data.progress || null);
        if (data.status === 'completed') {
          clearInterval(interval);
          setActiveStep(2);
          setLoading(false);      
          await handleDownloadAllImages(fileId);
        } else if (data.status === 'failed') {
          clearInterval(interval);
          throw new Error('Processing failed');
        }
      } catch (error) {
        clearInterval(interval);
        handleAPIError({
          error: error,
          setError: setError,
          setLoading: setLoading,
          setSuccess: setSuccess,
          translations: t
        });
      }
    }, 1000);

    return () => clearInterval(interval);
  };

  const handleDownloadAllImages = async (fileId) => {
    try {
      const { data } = await api.downloadAllImages(fileId, service.url);
      const imageUrls = data.images;

      const initialImages = imageUrls.map(() => ({ url: '', progress: 0 }));
      setImages(initialImages);

      for (const [index, imageUrl] of imageUrls.entries()) {
        const response = await api.downloadImage(imageUrl, index, imageUrls.length, service.url);
        const imageBlob = response.data;
        const imageObjectUrl = URL.createObjectURL(imageBlob);
        setImages(prevImages => {
          const newImages = [...prevImages];
          newImages[index] = { url: imageObjectUrl, progress: 100 };
          return newImages;
        });
      }

      setActiveStep(3);
      setSuccess(t.downloadSuccess);
    } catch (error) {
      setError(t.downloadError);
      console.error('Download failed:', error);
    }
  };

  const handleDownload = async (image, index) => {
    try {
      // Download file
      const link = document.createElement('a');
      link.href = image.url;
      link.download = `generated-${index}.png`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);      
      
      // Update user data after successful download
      await updateUserData({
        apiUrl,
        isAuthenticated,
        setUser,
        onError: (msg, err) => console.error(msg, err)
      });
      setError('');
      setSuccess(t.downloadSuccess);
    } catch (error) {
      setError(t.downloadError);
      console.error('Download failed:', error);
    }
  };

  
  const handleCopyExample = () => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(t.examplePrompt).then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 1000);
      }).catch(err => {
        console.error('Failed to copy text: ', err);
      });
    } else {
      // Fallback method
      const textArea = document.createElement('textarea');
      textArea.value = t.examplePrompt;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        document.execCommand('copy');
        setCopied(true);
        setTimeout(() => setCopied(false), 1000);
      } catch (err) {
        console.error('Fallback: Oops, unable to copy', err);
      }
      document.body.removeChild(textArea);
    }
  };


  return (
    <Container>
      
      <Typography variant="h1" gutterBottom sx={{ fontSize: { xs: '1.5rem', sm: '2rem', md: '2rem', lg: '2rem', xl: '2rem' }, fontWeight: 'bold' }}>
        {service?.title?.[language]}
      </Typography>
      <Typography variant="body1" gutterBottom sx={{ fontSize: { xs: '0.875rem', sm: '1rem', md: '1rem', lg: '1rem', xl: '1rem' } }}>
        {service?.description?.[language]}
      </Typography>

      <StepComponent activeStep={activeStep} t={t} />

      {error && <Alert severity="error" sx={{ mb: 2 }}>{Array.isArray(error) ? error.map((err, idx) => <div key={idx}>{err.msg}</div>) : error}</Alert>}
      {success && <Alert severity="success" sx={{ mb: 2 }}>{success}</Alert>}
      
      <Typography variant="body1" gutterBottom sx={{ color: 'red', fontSize: { xs: '0.875rem', sm: '1rem', md: '1rem', lg: '1rem', xl: '1rem' } }}>
        {t.promptGuidance}
      </Typography>
      
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mb: 2 }}>
        <Typography variant="body2" color="text.secondary" sx={{ mr: 1, fontStyle: 'italic' }}>
          {t.examplePrompt}
        </Typography>
        <IconButton onClick={handleCopyExample}>
          {copied ? <CheckIcon /> : <ContentCopyIcon />}
        </IconButton>
      </Box>

      <TextFieldStyled
        label={t.prompt}
        value={prompt}
        onChange={(e) => setPrompt(e.target.value)}
        fullWidth
        multiline
        maxRows={4}
      />

      <ButtonsContainer>
        <UploadButton
          onClick={() => inputRef.current?.click()}
          disabled={loading}
          label={uploadedImage ? t.newUploadButton : t.uploadButton}
        />
         <ProcessButton
          onClick={handleProcessClick}
          disabled={loading || !prompt}
          label={t.processButton}
        />
      </ButtonsContainer>

      <Box sx={{ width: '100%' }}>
        {loading && <LinearProgress sx={{ mb: 2 }} />}
      </Box>
      
      <Typography variant="body1" gutterBottom sx={{ color: 'red', fontSize: { xs: '0.875rem', sm: '1rem', md: '1rem', lg: '1rem', xl: '1rem' } }}>
        {t.refImageGuidance}
      </Typography>

      <ImageUploadFrame
        originalImage={uploadedImage}
        onFileSelect={setUploadedImage}
        loading={loading}
        translations={t}
        inputRef={inputRef}
        progress={activeStep === 0 ? uploadProgress : activeStep === 1 ? processProgress : downloadProgress}
        step={activeStep}
        setError={setError}
      />
      
      <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', mt: 4 }}>
        {images.map((image, index) => (
          <ImageCard
            key={index}
            image={image}
            index={index}
            onDownload={handleDownload}
            downloadProgress={downloadProgress}
            t={t}
          />
        ))}
      </Box>
      
      <ModalWindow
        open={modalOpen}
        handleClose={() => setModalOpen(false)}
        handleConfirm={handleConfirmProcess}
        title={t.confirmTitle}
        description={t.confirmTransactionDescription ? t.confirmTransactionDescription.replace('{price}', service?.price) : ''}
        confirmText={t.confirmText}
        cancelText={t.cancelText}
      />
    </Container>
  );
};

export default TextToImageAI;