// layouts/UserLayout.js
import React from 'react';
import { Box } from '@mui/material';
import BaseLayout from './BaseLayout';
import Header from '../components/Header';
import Footer from '../components/Footer';

const UserLayout = ({ children, language, setLanguage, isAuthenticated, logout }) => (
  <BaseLayout 
    Header={() => <Header /> }
    Footer={() => <Footer language={language} />}
  >
    <Box sx={{ paddingTop: '80px' }}> 
      {children}
    </Box>
  </BaseLayout>
);

export default UserLayout;
