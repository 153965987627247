import axios from 'axios';

const getToken = (isAuthenticated) => isAuthenticated ? 
  localStorage.getItem('token') : 
  localStorage.getItem('guestToken');

export const createApi = (apiUrl, isAuthenticated, setDownloadProgress) => {
  return {
    async uploadImage(file, onProgress, serviceUrl) {
        const formData = new FormData();
        formData.append('file', file);
        return axios.post(`${apiUrl}/ai/upload${serviceUrl}`, formData, {
          headers: { 'Authorization': `Bearer ${getToken(isAuthenticated)}` },
          onUploadProgress: (e) => {
            const progress = Math.round((e.loaded * 100) / e.total);
            onProgress?.(progress);
          }
        });
      },

    async processImage(fileId, serviceUrl) {
    return axios.post(`${apiUrl}/ai/process${serviceUrl}/${fileId}`, null, {
        headers: { 'Authorization': `Bearer ${getToken(isAuthenticated)}` }
    });
    },

    async generateImage(prompt, image, onProgress, serviceUrl) {
      const formData = new FormData();
      formData.append('prompt', prompt);
      if (image) {
        formData.append('image', image, image.name);
      }
      return axios.post(`${apiUrl}/ai${serviceUrl}`, formData, {
        headers: { 'Authorization': `Bearer ${getToken(isAuthenticated)}` },
        onUploadProgress: (e) => {
          const progress = Math.round((e.loaded * 100) / e.total);
          onProgress?.(progress);
        }
      });
    },

    async checkStatus(fileId, serviceUrl) {
      return axios.get(`${apiUrl}/ai/status${serviceUrl}/${fileId}`, {
        headers: { 'Authorization': `Bearer ${getToken(isAuthenticated)}` }
      });
    },

    async downloadAllImages(fileId, serviceUrl) {
      return axios.get(`${apiUrl}/ai/download-all-images${serviceUrl}/${fileId}`, {
        headers: { 'Authorization': `Bearer ${getToken(isAuthenticated)}` }
      });
    },

    async downloadImageByID(fileId, onProgress, serviceUrl) {
        return axios.get(`${apiUrl}/ai/download${serviceUrl}/${fileId}`, {
          headers: { 'Authorization': `Bearer ${getToken(isAuthenticated)}` },
          responseType: 'blob',
          onDownloadProgress: (e) => {
            const progress = Math.round((e.loaded * 100) / e.total);
            onProgress?.(progress);
          }
        });
    },

    async downloadImage(imageUrl, index, totalImages, serviceUrl) {
      return axios.post(`${apiUrl}/ai/download-image${serviceUrl}`, { image_url: imageUrl, image_number: index + 1, total_images: totalImages }, {
        headers: { 'Authorization': `Bearer ${getToken(isAuthenticated)}` },
        responseType: 'blob',
        onDownloadProgress: (e) => {
          const progress = Math.round((e.loaded * 100) / e.total);
          setDownloadProgress(prev => ({ ...prev, [index]: progress }));
        }
      });
    },
  };
};