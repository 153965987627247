import React, { useState, useContext } from 'react';
import { Typography, Alert } from '@mui/material';
import { AuthContext } from '../contexts/AuthContext';
import { useTranslations } from '../contexts/TranslationsContext';
import { ServicesContext } from '../contexts/ServicesContext';
import { ImageComparisonSlider } from './ImageServiceComponents/ImageComparisonSlider';
import { Container, StepComponent } from './ReusableComponents';

import { updateUserData } from './utils/userUtils';
import { createApi } from './utils/api';
import { handleProcess, pollStatus, handleDownload } from './utils/mediaNetWork';

const UpScaleAI = ({ language }) => {
  const { translations: t } = useTranslations();
  const { isAuthenticated } = useContext(AuthContext);
  const { services } = useContext(ServicesContext);
  const service = services.find((service) => service.url === '/image-upscale-ai');
  // State management
  const [activeStep, setActiveStep] = useState(0);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [downloadProgress, setDownloadProgress] = useState(0);
  const [processProgress, setProcessProgress] = useState(null);
  const [compareValue, setCompareValue] = useState(50);
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [resultImage, setResultImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const apiUrl = process.env.REACT_APP_API_URL;

  const api = React.useMemo(() => createApi(apiUrl, isAuthenticated, setDownloadProgress), [apiUrl, isAuthenticated]);

  const handleFileChange = (file) => {
    if (!file) return;
    
    setSelectedFile(file);
    const imageUrl = URL.createObjectURL(file);
    setPreviewImage(imageUrl);
    setResultImage(null);
    setError('');
    setActiveStep(0);
    setSuccess('');
  };

  const handleFileRemove = () => {
    setPreviewImage(null);
    setResultImage(null);
    setError('');
    setActiveStep(0);
    setSuccess('');
  };

  return (
    <Container>
      <Typography variant="h1" gutterBottom sx={{ fontSize: { xs: '1.5rem', sm: '2rem', md: '2rem', lg: '2rem', xl: '2rem' }, fontWeight: 'bold' }}>
        { service.title[language] }
      </Typography>
      <Typography variant="body1" gutterBottom sx={{ fontSize: { xs: '0.875rem', sm: '1rem', md: '1rem', lg: '1rem', xl: '1rem' } }}>
        { service.description[language] }
      </Typography>

      <StepComponent activeStep={activeStep} t={t} />

      {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
      {success && <Alert severity="success" sx={{ mb: 2 }}>{success}</Alert>}
      
      <ImageComparisonSlider
        originalImage={previewImage}
        processedImage={resultImage}
        value={compareValue}
        onChange={(_, value) => setCompareValue(value)}
        onFileSelect={handleFileChange}
        onFileRemove={handleFileRemove}
        onProcess={() => handleProcess({
          selectedFile,
          setLoading,
          setError,
          setActiveStep,
          setUploadProgress,
          service,
          t,
          pollStatus: pollStatus,
          setProcessProgress,
          setSuccess,
          setDownloadProgress,
          setResultImage,
          api
        })}
        loading={loading}
        progress={activeStep === 0 ? uploadProgress : activeStep === 1 ? processProgress : downloadProgress}
        step={activeStep}
        onDownload={() => handleDownload({
          resultImage,
          selectedFile,
          updateUserData,
          setError,
          setSuccess,
          t
        })}
        translations={t}
        service={service}
        setError={setError}
      />
      
    </Container>
  );
};

export default UpScaleAI;