import React, { useState, useContext } from 'react';
import { Button, TextField, Typography, Box, Alert, IconButton, InputAdornment, Link as MuiLink } from '@mui/material';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { AuthContext } from '../contexts/AuthContext';
import { useTranslations } from '../contexts/TranslationsContext';
import { styled } from '@mui/system';
import { Container } from './ReusableComponents';

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(4),
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[5],
  maxWidth: 400,
  margin: 'auto',
}));

// Login button styling
const LoginButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  backgroundColor: theme.palette.primary.main,
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const StyledLinkContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  paddingTop: theme.spacing(2),
  gap: theme.spacing(2),
}));

const Login = ({ language }) => {
    const {translations:t} = useTranslations();
    const apiUrl = process.env.REACT_APP_API_URL;
    const { login } = useContext(AuthContext); // Access login function from context
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [errors, setErrors] = useState({});
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState(''); // State for success message
    const [showForgotPasswordLink, setShowForgotPasswordLink] = useState(false); // State for forgot password link
    const [showRestoreUserLink, setShowRestoreUserLink] = useState(false); // State for forgot password link
    const navigate = useNavigate(); // Define navigate
    const [showResendButton, setShowResendButton] = useState(false);
    
    const validateForm = () => {
        const newErrors = {};

        if (!email) newErrors.email = t.emailRequired;
        if (!password) newErrors.password = t.passwordRequired;

        return newErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formErrors = validateForm();

        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
            setErrorMessage('');
        } else {
            const formData = new FormData();
            formData.append('username', email);
            formData.append('password', password);

            try {
                const response = await fetch(`${apiUrl}/login`, {
                    method: 'POST',
                    body: formData,
                });

                if (response.ok) {
                    const data = await response.json(); // assuming the token is in response data
                    localStorage.setItem('token', data.access_token); // Store token in localStorage
                    
                    login(data.access_token); // Call the login function to update the token in AuthContext
                    setErrorMessage('');
                    setSuccessMessage(t.loginSuccess); // Set success message
                    setTimeout(() => {
                        navigate('/');  // Redirect to home page after 2 seconds
                    }, 1000);
                } else {
                    const data = await response.json();
                    const { message, lockout_time_remaining, attempts_left } = data.detail;
                    
                    let translatedError = t.loginFailed;

                    if (message === "Account locked. Try again later.") {
                        translatedError = `${t.accountLocked} (${Math.floor(lockout_time_remaining / 60)}${t.minute}${Math.floor(lockout_time_remaining % 60)}${t.second} ${t.lockoutTimeRemaining})`;
                    } else if (message === "Invalid credentials.") {
                        translatedError = `${t.invalidCredentials}. ${attempts_left} ${t.attemptsLeft}`;
                        setShowForgotPasswordLink(true); // Show forgot password link
                    } else if (data.detail === "Invalid credentials.") {
                        translatedError = t.invalidCredentials; 
                        setShowForgotPasswordLink(true); // Show forgot password link
                    } else if (data.detail === "Please confirm your email to activate your account.") {
                        translatedError = t.accountInactive; 
                        setShowResendButton(true);
                    } else if (data.detail === "This account has been deactivated. Please contact support.") {
                        translatedError = t.accountDeleted; 
                        setShowRestoreUserLink(true);
                    }
                    setErrorMessage(translatedError);
                }
            } catch (error) {
                setErrorMessage(t.loginFailed);
            }

            setErrors({});
        }
    };

    const handleResendVerificationEmail = async () => {
        try {
            const response = await fetch(`${apiUrl}/email/resend-verification-email`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
            });
    
            if (response.ok) {
                setErrorMessage(t.verificationEmailSent);
                setShowResendButton(false);
            } else {
                setErrorMessage(t.verificationEmailFailed);
            }
        } catch (error) {
            setErrorMessage(t.verificationEmailFailed);
        }
    };
    
    return (
        <Container>
        <StyledBox>
          <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', color: 'text.main' }}>
            {t.loginTitle}
          </Typography>
          {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
          {successMessage && <Alert severity="success">{successMessage}</Alert>}
          <form onSubmit={handleSubmit} style={{ width: '100%' }}>
            <TextField 
              label={t.email}
              variant="outlined"
              margin="normal"
              fullWidth
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              error={!!errors.email}
              helperText={errors.email}
            />
            <TextField
              label={t.password}
              variant="outlined"
              type={showPassword ? 'text' : 'password'}
              margin="normal"
              fullWidth
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              error={!!errors.password}
              helperText={errors.password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <LoginButton type="submit" variant="contained" fullWidth>
              {t.submitLogin}
            </LoginButton>
            
            {showResendButton && (
              <Button variant="outlined" color="secondary" fullWidth onClick={handleResendVerificationEmail} sx={{ mt: 2 }}>
                {t.resendVerificationEmail}
              </Button>
            )}

            <StyledLinkContainer>
              {showForgotPasswordLink && (
                <MuiLink component={RouterLink} to="/forgot-password" sx={{ mt: 2 }}>
                  {t.forgotPassword}
                </MuiLink>
              )}
              {showRestoreUserLink && (
                <MuiLink component={RouterLink} to="/restore-account" target="_blank" rel="noopener noreferrer">
                  {t.restoreUser}
                </MuiLink>
              )}
              <MuiLink component={RouterLink} to="/signup" target="_blank" rel="noopener noreferrer">
                {t.signupLink}
              </MuiLink>
            </StyledLinkContainer>
          </form>
        </StyledBox>
        </Container>
      );
    };
    
    export default Login;