import React from 'react';
import { TextField, Button, IconButton, Grid, Divider } from '@mui/material';
import { Add, Delete } from '@mui/icons-material';

const ContentEditor = ({ content = [], setContent, validationErrors = {} }) => {
  const handleContentChange = (index, field, lang, value) => {
    const newContent = [...content];
    const newItem = { ...newContent[index] };

    if (field === 'media_url') {
      newItem[field] = value;
    } else {
      newItem[field] = { ...newItem[field], [lang]: value };
    }

    newContent[index] = newItem;
    setContent(newContent);
  };

  const handleAddContent = () => {
    const newContent = [...content, { title: { en: '', jp: '' }, body: { en: '', jp: '' }, media_url: '' }];
    setContent(newContent);
  };

  const handleRemoveContent = (index) => {
    const newContent = content.filter((_, i) => i !== index);
    setContent(newContent);
  };

  return (
    <div>
      <h3>Content</h3>
      {content.map((item, index) => (
        <div key={index}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={6}>
              <TextField
                label="Title (EN)"
                value={item.title.en || ''}
                onChange={(e) => handleContentChange(index, 'title', 'en', e.target.value)}
                fullWidth
                error={!!validationErrors[`content_title_en_${index}`]}
                helperText={validationErrors[`content_title_en_${index}`]}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Title (JP)"
                value={item.title.jp || ''}
                onChange={(e) => handleContentChange(index, 'title', 'jp', e.target.value)}
                fullWidth
                error={!!validationErrors[`content_title_jp_${index}`]}
                helperText={validationErrors[`content_title_jp_${index}`]}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Body (EN)"
                value={item.body.en || ''}
                onChange={(e) => handleContentChange(index, 'body', 'en', e.target.value)}
                fullWidth
                multiline
                rows={4}
                error={!!validationErrors[`content_body_en_${index}`]}
                helperText={validationErrors[`content_body_en_${index}`]}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Body (JP)"
                value={item.body.jp || ''}
                onChange={(e) => handleContentChange(index, 'body', 'jp', e.target.value)}
                fullWidth
                multiline
                rows={4}
                error={!!validationErrors[`content_body_jp_${index}`]}
                helperText={validationErrors[`content_body_jp_${index}`]}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Media URL"
                value={item.media_url || ''}
                onChange={(e) => handleContentChange(index, 'media_url', null, e.target.value)}
                fullWidth
                error={!!validationErrors[`content_media_url_${index}`]}
                helperText={validationErrors[`content_media_url_${index}`]}
              />
            </Grid>
            <Grid item xs={12}>
              <IconButton onClick={() => handleRemoveContent(index)}>
                <Delete />
              </IconButton>
            </Grid>
          </Grid>
          <Divider style={{ margin: '1rem 0' }} />
        </div>
      ))}
      <Button variant="contained" color="primary" onClick={handleAddContent} startIcon={<Add />}>
        Add Content
      </Button>
    </div>
  );
};

export default ContentEditor;