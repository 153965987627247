import { useContext } from 'react';
import { AuthContext } from './AuthContext';
import { UserContext } from './UserContext';
import { TranslationsContext } from './TranslationsContext';
import { ServicesContext } from './ServicesContext';

export const useCombinedLoading = () => {
    const authContext = useContext(AuthContext);
    const userContext = useContext(UserContext);
    const translationsContext = useContext(TranslationsContext);
    const servicesContext = useContext(ServicesContext);

    const authLoading = authContext ? authContext.loading : false;
    const userLoading = userContext ? userContext.loading : false;
    const translationsLoading = translationsContext ? translationsContext.loading : false;
    const servicesLoading = servicesContext ? servicesContext.loading : false;

    const combinedLoading = authLoading || userLoading || translationsLoading || servicesLoading;

    return combinedLoading;
};