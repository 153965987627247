import React, { createContext, useState, useEffect, useContext } from 'react';
import { AuthContext } from './AuthContext';
import axios from 'axios';

// Create TranslationsContext
export const TranslationsContext = createContext();
const apiUrl = process.env.REACT_APP_API_URL;

export const TranslationsProvider = ({ children }) => {
    const [translations, setTranslations] = useState({});
    const [loading, setLoading] = useState(true);
    const { language } = useContext(AuthContext);

    useEffect(() => {
        const fetchTranslations = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${apiUrl}/data/translations`);
                const data = response.data;

                const transformedTranslations = {};
                data.forEach(item => {
                    transformedTranslations[item.key] = item.value[language];
                });
                setTranslations(transformedTranslations);
            } catch (error) {
                console.error('Error fetching translations:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchTranslations();
    }, [language]);

    return (
        <TranslationsContext.Provider value={{ translations, loading }}>
            {children}
        </TranslationsContext.Provider>
    );
};

export const useTranslations = () => {
    return useContext(TranslationsContext);
};