import React, { createContext, useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

// Create ServicesContext
export const ServicesContext = createContext();
const apiUrl = process.env.REACT_APP_API_URL;

export const ServicesProvider = ({ children }) => {
    const [AIService, setAIService] = useState([]);
    const [services, setServices] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchServices = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${apiUrl}/data/services`);
                const data = response.data;

                setAIService(data.services);
            } catch (error) {
                console.error('Error fetching services:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchServices();
    }, []);

    useEffect(() => {
        const fetchServices = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${apiUrl}/data/services`);
                const data = response.data;

                setServices(data.services);
            } catch (error) {
                console.error('Error fetching services:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchServices();
    }, []);

    return (
        <ServicesContext.Provider value={{ AIService, services, loading, navigate }}>
            {children}
        </ServicesContext.Provider>
    );
};

export const useServices = () => {
    return useContext(ServicesContext);
};