import React, { useState } from 'react';
import { Box, Typography, Button, Alert, TextField } from '@mui/material';
import { useTranslations } from '../contexts/TranslationsContext';
import { styled } from '@mui/system';
import { Container } from './ReusableComponents';

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(4),
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[5],
  maxWidth: 400,
  margin: 'auto',
  textAlign: 'center',
}));

const SendButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  backgroundColor: theme.palette.primary.main,
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  width: '80%', // Make the input field smaller
}));

const RestoreAccount = ({ language }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const { translations: t } = useTranslations();
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [emailError, setEmailError] = useState('');

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSendVerificationEmail = async () => {
    if (!validateEmail(email)) {
      setEmailError(t.emailInvalid);
      return;
    } else {
      setEmailError('');
    }

    try {
      const response = await fetch(`${apiUrl}/email/send-restore-email`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        setMessage(t.verificationEmailSent);
        setError('');
      } else if (response.status === 429) {
        setError(t.tooManyRequests);
        setMessage('');
      } else {
        setError(t.verificationEmailFailed);
        setMessage('');
      }
    } catch (error) {
      setError(t.verificationEmailFailed);
      setMessage('');
    }
  };

  return (
    <Container>
      <StyledBox>
        <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', color: 'primary.main' }}>
          {t.restoreAccountTitle}
        </Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary', marginBottom: '16px' }}>
          {t.restoreAccountDescription}
        </Typography>
        <StyledTextField
          label={t.email}
          variant="outlined"
          margin="normal"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          error={!!emailError}
          helperText={emailError}
        />
        {message && <Alert severity="success">{message}</Alert>}
        {error && <Alert severity="error">{error}</Alert>}
        <SendButton variant="contained" color="primary" onClick={handleSendVerificationEmail}>
          {t.sendVerificationEmail}
        </SendButton>
      </StyledBox>
    </Container>
  );
};

export default RestoreAccount;