import React from 'react';
import { Box, Card, CardMedia, CardActions, styled } from '@mui/material';
import ImageIcon from '@mui/icons-material/Image';
import LinearProgress from '@mui/material/LinearProgress';
import { DownloadButton } from '../ReusableComponents';

const ImageCardStyled = styled(Card)(({ theme }) => ({
    width: '300px',
    backgroundColor: '#f5f5f5',
    border: '2px solid #E5A0FF',
    borderRadius: '8px',
    boxShadow: theme.shadows[2],
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: theme.spacing(2),
    position: 'relative',
    '&:hover': {
      transform: 'scale(1.05)',
      boxShadow: theme.shadows[4],
    },
  }));
  
  
  const CardMediaStyled = styled(CardMedia)(({ theme }) => ({
    width: '100%',
    height: 0,
    paddingTop: '100%',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  }));
  
  const CardActionsStyled = styled(CardActions)(({ theme }) => ({
    justifyContent: 'center',
  }));
  
  const PlaceholderBox = styled(Box)(({ theme }) => ({
    width: '100%',
    height: 0,
    paddingTop: '100%',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }));
  
  const PlaceholderIcon = styled(ImageIcon)(({ theme }) => ({
    fontSize: '100px',
    color: theme.palette.grey[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  }));
  
  const PlaceholderProgress = styled(LinearProgress)(({ theme }) => ({
    width: '60%',
    position: 'absolute',
    bottom: '10%',
    left: '50%',
    transform: 'translateX(-50%)',
  }));

const ImageCard = ({ image, index, onDownload, downloadProgress, t }) => {
    return (
      <ImageCardStyled>
        {image.url ? (
          <CardMediaStyled
            image={image.url}
            alt={`Generated ${index}`}
          />
        ) : (
          <PlaceholderBox>
            <PlaceholderIcon />
            <PlaceholderProgress 
              variant="determinate" 
              value={downloadProgress[index] || 0} 
            />
          </PlaceholderBox>
        )}
        <CardActionsStyled>
          <DownloadButton
            onClick={() => onDownload(image, index)}
            disabled={!image.url}
            label={t.downloadButton}
          />
        </CardActionsStyled>
      </ImageCardStyled>
    );
  };
  
  export default ImageCard;