import React from 'react';
import { styled, useTheme } from '@mui/system';
import { Box, Typography, Link as MuiLink, Container, useMediaQuery } from "@mui/material";
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import junaiLogo from './media/junai_logo_white.svg';
import HeaderNavItem from './HeaderComponents/HeaderNavItem';
import { useTranslations } from '../contexts/TranslationsContext';
import { Brand } from './Header';

const FooterOuterContainer = styled(Box)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.divider}`, 
  display: 'flex',
  justifyContent: 'center',
  padding: '10px 50px',
  backgroundColor: theme.palette.background.default,
}));

const FooterLinks = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  gap: theme.spacing(2),
  '& .MuiLink-root': {
    color: 'inherit',
    fontSize: theme.typography.body2.fontSize,
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
      marginLeft: 0,
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: 0,
      marginLeft: 'auto',
    },
  }
}));

const FooterContainer = styled(Box)(({ theme }) => ({
  maxWidth: '1500px',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.text.primary,
  textAlign: "center",
  padding: "20px 0",
  gap: '20px',
}));


const FooterNavItem = styled(HeaderNavItem)(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    gap: '20px',
    '& a': {
        fontSize: 'inherit',
    },
    color: theme.palette.text.primary,
}));

const Footer = () => {
  const navigate = useNavigate();
  const { translations } = useTranslations();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <FooterOuterContainer>
      <FooterContainer>
          <Brand 
              onClick={() => navigate('/')}
          >
              <img src={junaiLogo} alt="Junai Logo" />
          </Brand>

          <FooterNavItem isHeader={false} />

          <Container maxWidth="lg" sx={{ 
            borderTop: `1px solid ${theme.palette.divider}`, 
            mt: 2, 
          }}> 
            <Box sx={{ 
              display: "flex", 
              flexDirection: isMobile ? 'column' : 'row', 
              justifyContent: "space-between", 
              alignItems: "center", 
              padding: "10px 0",
            }}>
              <FooterLinks>
                <MuiLink component={RouterLink} to="/privacy-policy" color="inherit" >
                    {translations['privacy_policy.title']}
                </MuiLink>
                <MuiLink component={RouterLink} to="/term-of-service" color="inherit" >
                    {translations['tos.title']}
                </MuiLink>
              </FooterLinks>
              <Typography variant="body2" sx={{ mt: isMobile ? 2 : 0, ml: isMobile ? 0 : 'auto' }}>
                © 2024 JunAI. All rights reserved.
              </Typography>
            </Box>
          </Container>
      </FooterContainer>
    </FooterOuterContainer>
  );
};

export default Footer;