export const ERROR_TYPES = {
  FILE_SIZE: 413,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  INVALID_FILE: 415,
  SERVER_ERROR: 500,
  NOT_FOUND: 404 // Added 404 error type
};

export const handleAPIError = ({
  error,
  setError,
  setLoading = null,
  setSuccess = null,
  translations
}) => {
  // Reset states if provided
  setLoading?.(false);
  setSuccess?.('');

  if (error && error.response) {
    switch (error.response.status) {
      
      case ERROR_TYPES.FILE_SIZE:
        setError(translations.errorExceededSize);
        break;
      case ERROR_TYPES.BAD_REQUEST:
        setError(translations.notEnoughTokens);
        break;
      case ERROR_TYPES.UNAUTHORIZED:
        setError(translations.invalidTokenTitle);
        break;
      case ERROR_TYPES.INVALID_FILE:
        setError(translations.errorFileType);
        break;
      case ERROR_TYPES.SERVER_ERROR:
        setError(translations.errorProcessingImage);
        break;
      case ERROR_TYPES.NOT_FOUND:
        setError(translations.errorNotFound); // Handle 404 error
        break;
      default:
        setError(error.response.data?.detail || translations.processFailed);
    }
  } else if (error && error.request) {
    // The request was made but no response was received
    console.error('API Error: No response received', error.request);
    setError(translations.noResponse);
  } else if (error && error.message) {
    // Something happened in setting up the request that triggered an Error
    console.error('API Error:', error.message);
    setError(error.message || translations.generalError);
  } else {
    // Handle any other case where error object might not be as expected
    console.error('API Error: Unknown error', error);
    setError(translations.generalError);
  }
};