import React, { useContext } from 'react';
import SettingsIcon from '@mui/icons-material/Settings';
import { useNavigate } from 'react-router-dom';
import TokenIcon from '@mui/icons-material/Token';
import { AuthContext } from '../../contexts/AuthContext';
import { UserContext } from '../../contexts/UserContext';
import { useTranslations } from '../../contexts/TranslationsContext';
import { Menu, MenuItem } from '@mui/material';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';

const UserMenu = ({anchorEl, handleMenuClose}) => {
    const navigate = useNavigate();
    const isMenuOpen = Boolean(anchorEl);
    const { translations } = useTranslations();
    const { user } = useContext(UserContext);
    const { isAuthenticated, logout } = useContext(AuthContext);
    
    const handleLogout = () => {
        logout();
        handleMenuClose();
        navigate('/');
    };

    const menuItems = [
        <MenuItem 
            variant='unclickable'
            key="user-credit"
        >
            <TokenIcon sx={{ marginRight: 1 }} />
            {user?.credit}
        </MenuItem>,

    ];

    if (isAuthenticated) {
        menuItems.push(
            <MenuItem
                variant='fancy' 
                key="account-settings" 
                onClick={() => { navigate('/account-settings'); handleMenuClose(); }}
            >
                <SettingsIcon sx={{ marginRight: 1 }} />
                {translations.account_settings}
            </MenuItem>,
            <MenuItem
                variant='fancy'
                key="logout"
                onClick={handleLogout}
            >
                <LogoutIcon sx={{ marginRight: 1 }} />
                {translations.logout}
            </MenuItem>
        );
    } else {
        menuItems.push(
            <MenuItem
                variant='fancy'
                key="login"
                onClick={() => { navigate('/login'); handleMenuClose(); }}
            >
                <LoginIcon sx={{ marginRight: 1 }} />
                {translations.login}
            </MenuItem>
        );
    }

    return (
        <Menu
            anchorEl={anchorEl}
            open={isMenuOpen}
            onClose={handleMenuClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            {menuItems}
        </Menu>
    );
};

export default UserMenu;