// layouts/BaseLayout.js
import React from 'react';

const BaseLayout = ({ Header, Footer, children }) => (
  <>
    <Header />
    <main>
      {children}
    </main>
    <Footer />
  </>
);

export default BaseLayout;