// Create a new file: SwiperSlideshow.js
import React, { useRef, useEffect, useState } from 'react';
import { Box, Button } from '@mui/material';
import { styled } from '@mui/system';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

const NavigationContainer = styled(Box)({
  position: 'absolute',
  top: '50%',
  left: 0,
  right: 0,
  transform: 'translateY(-50%)',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  pointerEvents: 'none',
  zIndex: 2,
});

const NavigationButton = styled(Button)(({ theme }) => ({
  pointerEvents: 'auto',
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  backdropFilter: 'blur(8px)',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
  },
  padding: theme.spacing(0),
  borderRadius: '8px',
}));

const SwiperContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  maxWidth: '1600px',
  '& .swiper': {
    padding: theme.spacing(5, 2),
  },
  '& .swiper-slide': {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  '& .swiper-button-next::after, & .swiper-button-prev::after': {
    display: 'none',
  },
  '& .swiper-button-disabled': {
    display: 'disaled',
  },
  '& .swiper-pagination': {
    marginTop: theme.spacing(4),
    position: 'relative',
    bottom: 'auto',
  },
  '& .swiper-pagination-bullet': {
    width: '10px',
    height: '10px',
    margin: '0 6px',
    opacity: 0.5,
    transition: 'all 0.3s ease',
    backgroundColor: theme.palette.mode === 'dark' 
      ? '#FFFFFF'
      : '#6B2E99',
    '&:hover': {
      opacity: 0.8,
    },
    '&.swiper-pagination-bullet-active': {
      opacity: 1,
      backgroundColor: theme.palette.mode === 'dark'
        ? '#00F5D4'
        : '#9D4EDD',
      transform: 'scale(1.2)',
    },
  },
}));

const swiperSettings = {
  modules: [Navigation, Pagination, Autoplay],
  spaceBetween: 5,
  centeredSlides: true,
  slidesPerView: "auto",
  autoplay: {
    delay: 5000,
    disableOnInteraction: false,
    pauseOnMouseEnter: true,
  },
  loop: false,
  navigation: {
    prevEl: ".swiper-button-prev",
    nextEl: ".swiper-button-next",
  },
  pagination: {
    clickable: true,
    dynamicBullets: true,
  },
  breakpoints: {
    320: { slidesPerView: 1, centeredSlides: true, spaceBetween: 20 },
    480: { slidesPerView: 1, centeredSlides: true, spaceBetween: 30 },
    768: { slidesPerView: 2, centeredSlides: false, spaceBetween: 30 },
    992: { slidesPerView: 2, centeredSlides: false, spaceBetween: 40 },
    1200: { slidesPerView: 3, centeredSlides: false, spaceBetween: 40 },
    1600: { slidesPerView: 4, centeredSlides: false, spaceBetween: 40 },
  },
};

const SwiperSlideshow = ({ slides }) => {
  const [showNavigation, setShowNavigation] = useState(true);
  const [loop, setLoop] = useState(false);
  const swiperRef = useRef(null);

  const handleSwiperInit = (swiper) => {
    swiperRef.current = swiper;
    const updateSwiperSettings = () => {
      const { slidesPerView } = swiper.params;
      const shouldShowNav = slides.length > slidesPerView;
      setShowNavigation(shouldShowNav);
      setLoop(slides.length > slidesPerView);

      // Force immediate update
      setTimeout(() => {
        swiper.update();
        swiper.navigation.update();
      }, 0);
    };

    updateSwiperSettings();
    swiper.on('breakpoint', updateSwiperSettings);
    swiper.on('resize', updateSwiperSettings);

    return () => {
      swiper.off('breakpoint', updateSwiperSettings);
      swiper.off('resize', updateSwiperSettings);
      swiperRef.current = null;
    };
  };

  useEffect(() => {
    if (swiperRef.current) {
      swiperRef.current.params.loop = loop;
      swiperRef.current.update();
    }
  }, [loop]);

  return (
    <SwiperContainer>
      <Swiper 
        {...swiperSettings}
        onSwiper={handleSwiperInit}
      >
        {slides.map((slide, index) => (
          <SwiperSlide key={index}>
            {slide}
          </SwiperSlide>
        ))}
      </Swiper>
      
      {showNavigation && (
        <NavigationContainer>
          <NavigationButton className="swiper-button-prev">
            <NavigateBeforeIcon />
          </NavigationButton>
          <NavigationButton className="swiper-button-next">
            <NavigateNextIcon />
          </NavigationButton>
        </NavigationContainer>
      )}
    </SwiperContainer>
  );
};

export default SwiperSlideshow;