import React, { useContext } from 'react';
import LanguageIcon from '@mui/icons-material/Language';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Button } from '@mui/material';
import { AuthContext } from '../../contexts/AuthContext';
import { useTranslations } from '../../contexts/TranslationsContext';
import { Menu, MenuItem } from '@mui/material';

const LanguageSelector = ({ onLanguageChange }) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { translations } = useTranslations();
    const { initializeGuestSession, language, setLanguage } = useContext(AuthContext);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = async (lang) => {
        const token = localStorage.getItem('token');
        const url = token
          ? `${apiUrl}/users/language/logged`
          : `${apiUrl}/users/language/guest`;
      
        const attemptLanguageUpdate = async () => {
          try {
            const response = await fetch(url, {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token') || localStorage.getItem('guestToken')}`,
              },
              body: JSON.stringify({ language: lang }),
            });
      
            if (response.ok) {
              setLanguage(lang);
              setAnchorEl(null);
              if (onLanguageChange) {
                onLanguageChange();
              }
              return true;
            }
            return false;
          } catch (error) {
            console.error('Error updating language:', error);
            return false;
          }
        };
      
        // First attempt
        const firstAttempt = await attemptLanguageUpdate();
        if (!firstAttempt) {
          console.log('First attempt failed, initializing guest session...');
          await initializeGuestSession();
          
          // Retry after guest session initialization
          const secondAttempt = await attemptLanguageUpdate();
          if (!secondAttempt) {
            console.error('Language update failed after guest session initialization');
          }
        }
      };
    
    return (
        <div>
            <Button
                variant='transparent'
                onClick={handleClick}
                startIcon={<LanguageIcon />}
                endIcon={<ArrowDropDownIcon />} // Add ArrowDropDownIcon
                sx={{ 
                  '& .MuiButton-endIcon': {
                  marginLeft: '-8px',
                  '& svg': {
                      fontSize: '1.2rem', // Match parent font size
                  }
                }
                }}
            >
                {translations.language}
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => handleClose(language)}
            >
                <MenuItem 
                    variant='fancy'
                    onClick={() => handleClose('en')}
                >
                    English
                </MenuItem>
                <MenuItem 
                    variant='fancy'
                    onClick={() => handleClose('jp')}
                >
                    日本語
                </MenuItem>
            </Menu>
        </div>
    );
};

export default LanguageSelector;