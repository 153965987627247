import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';
import { Menu, MenuItem } from '@mui/material';

const ServiceMenu = ({ anchorEl, handleClose, items }) => {
    
    const { language } = useContext(AuthContext);

    const navigate = useNavigate();

    const handleMenuItemClick = (url) => {
        navigate(url);
        handleClose();
    };
    
    return (
        <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            MenuListProps={{
                onClick: handleClose,
            }}
        >
            {items.map((item) => (
                <MenuItem
                    variant='fancy'
                    key={item.id}
                    onClick={() => handleMenuItemClick(item.url)}
                >
                    {item.title[language]}
                </MenuItem>
            ))}
        </Menu>
    );
};

export default ServiceMenu;