import React from 'react';
import { Container, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { useTranslations } from '../contexts/TranslationsContext';

const StyledContainer = styled(Container)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(4),
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[5],
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.main,
  marginBottom: theme.spacing(2),
  fontSize: theme.typography.h5.fontSize,
  [theme.breakpoints.down('sm')]: {
    fontSize: theme.typography.h6.fontSize,
  },
}));

const SectionContent = styled('div')(({ theme }) => ({
  color: theme.palette.text.primary,
  marginBottom: theme.spacing(4),
  fontSize: theme.typography.body1.fontSize,
  [theme.breakpoints.down('sm')]: {
    fontSize: theme.typography.body2.fontSize,
  },
}));

const ContentLine = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  fontSize: theme.typography.body1.fontSize,
  [theme.breakpoints.down('sm')]: {
    fontSize: theme.typography.body2.fontSize,
  },
}));

const Section = ({ sectionNumber, translations }) => (
  <>
    <SectionTitle variant="h5" component="h2">
      {translations[`privacy_policy.section_${sectionNumber}.title`]}
    </SectionTitle>
    <SectionContent>
      {renderContentWithNewLines(translations[`privacy_policy.section_${sectionNumber}.content`])}
    </SectionContent>
  </>
);

const renderContentWithNewLines = (content) => {
  if (!content) return null;
  return content.split('\n').map((line, index) => (
    <ContentLine key={index} component="div" variant="body1">
      {line}
    </ContentLine>
  ));
};

const PrivacyPolicy = () => {
  const { translations } = useTranslations();
  const sections = Array.from({ length: 8 }, (_, i) => i + 1);

  return (
    <StyledContainer maxWidth="md">
      <Typography 
        variant="h3" 
        component="h1" 
        gutterBottom 
        sx={{ color: 'text.main', fontWeight: 'bold', textAlign: 'center' }}
      >
        {translations['privacy_policy.title']}
      </Typography>

      <Typography 
        variant="subtitle1" 
        component="div" 
        gutterBottom 
        sx={{ color: 'text.secondary' }}
      >
        {translations['privacy_policy.effective_date']}
      </Typography>

      <SectionContent>
        {renderContentWithNewLines(translations['privacy_policy.introduction.content'])}
      </SectionContent>

      {sections.map(number => (
        <Section
          key={number}
          sectionNumber={number}
          translations={translations}
        />
      ))}
    </StyledContainer>
  );
};

export default PrivacyPolicy;