import React, { useState, useRef } from 'react';
import { Box, Typography, CircularProgress, Slider, LinearProgress } from '@mui/material';
import { styled } from '@mui/system';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloseIcon from '@mui/icons-material/Close';
import { ButtonsContainer, UploadButton, ProcessButton, DownloadButton, ModalWindow, RemoveButton } from '../ReusableComponents';
import { ImageContainer, DragOverlay, ProgressOverlay, DropZone } from './ImageUploadFrame';

const Image = styled('img')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  objectFit: 'contain',
});


export const ImageComparisonSlider = ({
  originalImage,
  processedImage,
  value,
  onChange,
  onFileSelect,
  onFileRemove,
  onProcess,
  loading,
  progress,
  step,
  onDownload,
  translations: t,
  service,
  setError, 
}) => {
  const IMAGE_SIZE_LIMIT = 4 * 1024 * 1024; // 4MB in bytes
  const ALLOWED_TYPES = ['image/jpeg', 'image/png', 'image/bmp', 'image/tiff'];

  const [isDragging, setIsDragging] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const inputRef = useRef(null);

  const validateFile = (file) => {
    if (!file) return false;
    
    // Check file type
    if (!ALLOWED_TYPES.includes(file.type)) {
      setError(t.errorFileType);
      return false;
    }

    // Check file size
    if (file.size > IMAGE_SIZE_LIMIT) {
      setError(t.errorExceededSize);
      return false;
    }

    return true;
  };

  const handleFileSelect = (file) => {
    if (file instanceof File) {
      if (validateFile(file)) {
        setError(null);
        onFileSelect(file);
      }
    }
  };

  const handleInputChange = (e) => {
    const file = e?.target?.files?.[0];
    if (file) {
      handleFileSelect(file);
    }
  };

  const handleDragEvents = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    handleDragEvents(e);
    setIsDragging(false);
    const file = e.dataTransfer?.files?.[0];
    if (file) {
      handleFileSelect(file);
    }
  };

  const handleProcessClick = () => {
    setModalOpen(true);
  };

  const handleConfirmProcess = () => {
    setModalOpen(false);
    onProcess();
  };

  const handleRemoveImage = () => {
    onFileRemove();
  };

  return (
    <>
      <ButtonsContainer>
        <input
          ref={inputRef}
          type="file"
          hidden
          onChange={handleInputChange}
          accept="image/*"
        />
        
        {!originalImage && step === 0 && (
          <UploadButton
            onClick={() => inputRef.current?.click()}
            disabled={loading}
            label={t.uploadButton}
          />
        )}

        {originalImage && (
          <UploadButton
            onClick={() => inputRef.current?.click()}
            disabled={loading}
            label={t.newUploadButton}
          />
        )}

        {originalImage && step < 2 && (
          <ProcessButton
            onClick={handleProcessClick}
            disabled={loading}
            label={t.processButton}
          />
        )}
        
        {step >= 2 && (
          <DownloadButton
            onClick={onDownload}
            disabled={loading}
            label={t.downloadButton}
          />
        )}
      </ButtonsContainer>

      <ImageContainer
        data-dragging={isDragging}
        onDragEnter={() => setIsDragging(true)}
        onDragLeave={() => setIsDragging(false)}
        onDragOver={handleDragEvents}
        onDrop={handleDrop}
      >
        {!originalImage && (
          <DropZone 
            isDragging={isDragging}
            onClick={(e) => {
                inputRef.current?.click();
            }}        
          >
            {!isDragging && (
            <Box 
              sx={{ 
              display: 'flex', 
              flexDirection: 'column',
              alignItems: 'center', 
              justifyContent: 'center',
              mb: 2 
            }}>
              <Box sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center', 
                height: '50px', 
                mx: 'auto' 
              }}>
                <CloudUploadIcon 

                  sx={{ 
                    fontSize: 48, 
                    color: '#4A0072',
                    transition: 'all 0.3s ease',
                    '&:hover': {
                      fontSize: 56, // Increase size on hover
                      color: '#8A2BE2' // Change color on hover
                    }
                  }} 
                />
              </Box>
              
              <Box sx={{ textAlign: 'center', maxWidth: '300px', mx: 'auto' }}>
                <Typography 
                  variant="h6" 
                  color="textSecondary" 
                  sx={{ fontSize: { xs: '1rem', sm: '1.25rem' } }}
                >
                  {t.dragDropText}
                </Typography>
                <Typography 
                  variant="body2" 
                  color="text.secondary" 
                  sx={{ mt: 2, color: 'red', fontSize: { xs: '0.75rem', sm: '0.875rem' } }}
                >
                  {t.supportImageType}
                </Typography>
              </Box>
            </Box>
          )}
          </DropZone>
        )}

        {originalImage && (
          <>
            { !loading && (
              <RemoveButton onClick={handleRemoveImage}>
                <CloseIcon />
              </RemoveButton>
            )}
            <Image src={processedImage || originalImage} alt="Result" />
            {processedImage && (
              <>
                <Image
                  src={originalImage}
                  alt="Original"
                  sx={{
                    clipPath: `inset(0 ${100-value}% 0 0)`,
                  }}
                />
                <Slider
                  value={value}
                  onChange={onChange}
                  sx={{
                    position: 'absolute',
                    bottom: 24,
                    left: '50%',
                    transform: 'translateX(-50%)',
                    width: '100%',
                    zIndex: 3,
                  }}
                />
              </>
            )}
          </>
        )}

        <DragOverlay isDragging={isDragging}>
          <CloudUploadIcon sx={{ fontSize: 48, mb: 2 }} />
          <Typography variant="h6">
            {t.dropText}
          </Typography>
        </DragOverlay>

        {loading && (
          <ProgressOverlay>
            <CircularProgress size={48} sx={{ mb: 2 }} />
            <Typography variant="h6" gutterBottom>
                {step === 0 ? t.uploadingText : step === 1 ? t.processingText : t.downloadingText}
            </Typography>
            {progress !== null &&  (
              <LinearProgress variant="determinate" value={progress} sx={{ width: '30%', mb: 2 }}/>
            )}
          </ProgressOverlay>
        )}
      </ImageContainer>

      <ModalWindow
        open={modalOpen}
        handleClose={() => setModalOpen(false)}
        handleConfirm={handleConfirmProcess}
        title={t.confirmTitle}
        description={t.confirmTransactionDescription ? t.confirmTransactionDescription.replace('{price}', service?.price) : ''}
        confirmText={t.confirmText}
        cancelText={t.cancelText}
      />
    </>
  );
};