import React, { useState, useContext } from "react";
import { Box, Typography, Button, Alert, TextField, Card } from "@mui/material";
import { styled } from "@mui/system";
import { useTranslations } from "../contexts/TranslationsContext";
import { AuthContext } from "../contexts/AuthContext";
import EmailIcon from "@mui/icons-material/Email";
import SendIcon from "@mui/icons-material/Send";
import TopPageSection from "./TopPageSection";

const ContentBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  maxWidth: '1600px',
  margin: '0 auto',
  gap: theme.spacing(4),
  width: '100%',
  flexDirection: 'row',
  justifyContent: 'space-between',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  }
}));

const InfoCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  color: theme.palette.text.primary,
  textAlign: "center",
  width: '35%',
  [theme.breakpoints.down('md')]: {
    width: '100%',
  }
}));

const FormCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  width: '65%',
  [theme.breakpoints.down('md')]: {
    width: '100%',
  }
  
}));

const ContactSection = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const { translations: t } = useTranslations();
  const [formData, setFormData] = useState({ name: "", email: "", message: "" });
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const { isAuthenticated, token, guestToken } = useContext(AuthContext);

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = t.nameRequired;
    if (!formData.email) {
      newErrors.email = t.emailRequired;
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      newErrors.email = t.emailInvalid;
    }
    if (!formData.message) newErrors.message = t.messageRequired;
    return newErrors;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async () => {
    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      setSuccessMessage("");
      setErrorMessage("");
      return;
    }
    setErrors({});
    try {
      const currentToken = isAuthenticated ? token : guestToken;

      const response = await fetch(`${apiUrl}/contact/send-email`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${currentToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      if (response.ok) {
        setSuccessMessage(t.contactSuccess);
        setErrorMessage("");
        setFormData({ name: "", email: "", message: "" }); // Reset form on success
      } else {
        setErrorMessage(t.contactFailed);
        setSuccessMessage("");
      }
    } catch (error) {
      setErrorMessage(t.contactFailed);
      setSuccessMessage("");
    }
  };

  return (
    <TopPageSection 
      title={t.contactUsTitle} 
      description={t.contactUsDescription}
    >
    <ContentBox>
        <InfoCard variant="gradient">
          <a href="mailto:contact@junai.info" style={{ color: "inherit", textDecoration: "none" }}>
            <EmailIcon 
              sx={{ 
                fontSize: 40, 
                marginBottom: 2, 
                color: "primary.main",
                cursor: 'pointer',
                '&:hover': {
                  color: 'linear-gradient(90deg, #00F5D4, #3A86FF)',
                  transform: 'scale(1.1)',
                  transition: 'transform 0.2s ease-in-out'
                }
              }} 
            />
          </a>
          <Typography variant="h6" gutterBottom sx={{ color: "text.primary" }}>
            {t.contactUsInfoTitle}
          </Typography>
          <Typography variant="body2" gutterBottom sx={{ color: "text.secondary" }}>
            {t.contactUsInfoDescription}
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: "bold", color: "text.primary" }}>
            <a
              href="mailto:contact@junai.info"
              style={{ color: "inherit", textDecoration: "none" }}
            >
              contact@junai.info
            </a>
          </Typography>
        </InfoCard>
  
        <FormCard variant='gradient'>
          <TextField
            label={t.name}
            name="name"
            placeholder={t.namePlaceholder}
            variant="outlined"
            value={formData.name}
            onChange={handleInputChange}
            error={!!errors.name}
            helperText={errors.name}
            fullWidth
            sx={{
              marginBottom: 2,
              "& .MuiInputBase-input": { color: "text.primary" },
              "& .MuiFormLabel-root": { color: "text.secondary" },
            }}
          />
          <TextField
            label={t.email}
            name="email"
            placeholder={t.emailPlaceholder}
            variant="outlined"
            value={formData.email}
            onChange={handleInputChange}
            error={!!errors.email}
            helperText={errors.email}
            fullWidth
            sx={{
              marginBottom: 2,
              "& .MuiInputBase-input": { color: "text.primary" },
              "& .MuiFormLabel-root": { color: "text.secondary" },
            }}
          />
          <TextField
            label={t.message}
            name="message"
            placeholder={t.messagePlaceholder}
            variant="outlined"
            multiline
            rows={4}
            value={formData.message}
            onChange={handleInputChange}
            error={!!errors.message}
            helperText={errors.message}
            fullWidth
            sx={{
              marginBottom: 2,
              "& .MuiInputBase-input": { color: "text.primary" },
              "& .MuiFormLabel-root": { color: "text.secondary" },
            }}
          />
          {successMessage && (
            <Alert severity="success" sx={{ marginBottom: 2, color: "text.primary" }}>
              {successMessage}
            </Alert>
          )}
          {errorMessage && (
            <Alert severity="error" sx={{ marginBottom: 2, color: "text.primary" }}>
              {errorMessage}
            </Alert>
          )}
          <Button
            variant="gradient"
            onClick={handleSubmit}
            endIcon={<SendIcon />}
          >
            {t.sendMessage}
          </Button>
        </FormCard>
      </ContentBox>
    </TopPageSection>
  );
};

export default ContactSection;