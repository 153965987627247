import { createTheme } from "@mui/material";

const commonSettings = {
  typography: {
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    button: {
      textTransform: "none",
      fontWeight: 500,
    },
  },
  spacing: 8,
  shape: {
    borderRadius: 8, // Add default borderRadius
  },
  components: {
    MuiContainer: {
      styleOverrides: {
        root: ({ theme }) => ({
          maxWidth: '100%',
          width: '100%',
          gap: theme.spacing(2),
          padding: theme.spacing(0, 2),
          [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(0, 3),
          },
          [theme.breakpoints.up('md')]: {
            padding: theme.spacing(0, 4),
          },
          [theme.breakpoints.up('lg')]: {
            padding: theme.spacing(0, 6),
          },
        }),
      },
    },
    MuiBrand: {
      styleOverrides: {
        root: ({ theme }) => ({
          cursor: 'pointer',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: theme.spacing(1),
          transition: 'all 0.3s ease',
          width: 'auto',
          '& img': {
            height: '60px',
            filter: theme.palette.mode === 'dark' 
              ? 'brightness(1)' 
              : 'brightness(0)',
            transition: 'filter 0.3s ease',
          },
        }),
      },
      variants: [
        {
          props: { variant: 'header' },
          style: {
            '& img': {
              height: '60px',
            },
          },
        },
      ],
    },

    MuiMenuItem: {
      variants: [
        {
          props: { variant: 'fancy' },
          style: ({ theme }) => ({
            color: theme.palette.text.primary,
            fontWeight: '500',
            borderRadius: theme.shape.borderRadius,
            position: 'relative',
            backgroundColor: theme.palette.mode === 'dark'
              ? 'rgba(0, 0, 0, 0.2)'
              : 'rgba(255, 255, 255, 0.1)',
            transition: 'background-color 0.3s ease',
            '&::after': {
              content: '""',
              position: 'absolute',
              left: 0,
              bottom: -2,
              width: '0%',
              height: '2px',
              backgroundColor: theme.palette.text.primary,
              transition: 'width 0.3s ease-in-out',
            },
            '&:hover::after': {
              width: '100%',
            },
            '&:hover': {
              backgroundColor: theme.palette.mode === 'dark'
                ? 'rgba(255, 255, 255, 0.1)'
                : 'rgba(255, 255, 255, 0.2)',
            },
          }),
        },
        {
          props: { variant: 'unclickable' },
          style: ({ theme }) => ({
            color: theme.palette.text.primary,
            cursor: 'default',
            pointerEvents: 'none',
            fontWeight: 'bold',
            borderRadius: '0px',
            justifyContent: 'center',
          }),
        },
      ],
    },

    MuiMenu: {
      styleOverrides: {
        paper: ({ theme }) => ({
          background: theme.palette.mode === 'dark'
            ? 'linear-gradient(120deg, #6B2E99, #1E4ED8)'
            : 'linear-gradient(120deg, #D8B4FE, #818CF8)',
          color: theme.palette.text.primary,
          borderRadius: theme.shape.borderRadius,
          boxShadow: theme.palette.mode === 'dark'
            ? '0 4px 12px rgba(0, 0, 0, 0.5)'
            : '0 4px 12px rgba(0, 0, 0, 0.3)',
          transition: 'background 0.3s ease, box-shadow 0.3s ease',
          padding: theme.spacing(0, 1),
          '& .MuiMenuItem-root': {
            borderRadius: theme.shape.borderRadius,
            margin: '2px 0',
            transition: 'background-color 0.3s ease',
          },
        }),
      },
    },
    
    MuiLink: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.primary.main,
          textDecoration: 'none',
          transition: 'color 0.3s ease',
          position: 'relative',
          
          '&:hover': {
            color: theme.palette.primary.main,
            textDecoration: 'none',
          },
          '&::after': {
            content: '""',
            position: 'absolute',
            left: 0,
            bottom: -2,
            width: '0%',
            height: '2px',
            backgroundColor: theme.palette.primary.main,
            transition: 'width 0.3s ease-in-out',
          },
          '&:hover::after': {
            width: '100%',
          },
        }),
      },
      variants: [
        {
          props: { variant: 'footer' },
          style: ({ theme }) => ({
            color: theme.palette.text.secondary,
            '&:hover': {
              color: theme.palette.primary.main,
            },
          }),
        },
      ],
    },

    MuiButton: {
      variants: [
        {
          props: { variant: 'gradient' },
          style: ({ theme }) => ({
            color: '#FFFFFF', // White text for both modes
            background: 'linear-gradient(120deg, #9D4EDD, #FF5E9C)',
            borderRadius: '8px',
            border: '0.5px solid transparent',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
            transition: 'all 0.3s ease',
            padding: '4px 8px',
            fontSize: '1rem',
            fontWeight: 'bold',
            textTransform: 'none',
            gap: '8px',
            '& .MuiButton-startIcon': {
              margin: 0,
              '& svg': {
                fontSize: '1.5rem', // Match parent font size
              }
            },
            '& .MuiButton-endIcon': {
              margin: 0,
              '& svg': {
                fontSize: '1.5rem', // Match parent font size
              }
            },
            '&:hover': {
              color: '#FFFFFF',
              border: '0.5px solid transparent',
              background: 'linear-gradient(120deg, #00F5D4, #3A86FF)', // Deeper purple on hover
              transform: 'scale(1.05)',
              boxShadow: '0 0 15px rgba(0, 245, 212, 0.6)',
            
            },
          }),
        },
        {
          props: { variant: 'transparent' },
          style: ({ theme }) => ({
            color: theme.palette.text.primary,
            background: theme.palette.mode === 'dark'
              ? 'rgba(255, 255, 255, 0.1)'
              : 'rgba(255, 255, 255, 0.2)',
            backdropFilter: 'blur(8px)',
            WebkitBackdropFilter: 'blur(8px)', // For Safari support
            borderRadius: '8px',
            border: '0.5px solid rgba(255, 255, 255, 0.3)',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            transition: 'all 0.3s ease',
            padding: '4px 8px',
            fontSize: '1rem',
            fontWeight: 'bold',
            textTransform: 'none',
            gap: '8px',
            '& .MuiButton-startIcon': {
              margin: 0,
              '& svg': {
                fontSize: '1.5rem', // Match parent font size
              }
            },
            '& .MuiButton-endIcon': {
              margin: 0,
              '& svg': {
                fontSize: '1.5rem', // Match parent font size
              }
            },
            '&:hover': {
              color: '#FFFFFF',
              border: '0.5px solid #00B4D8',
              background: 'linear-gradient(90deg, #00F5D4, #3A86FF)',
              transform: 'scale(1.05)',
              boxShadow: '0 0 15px rgba(0, 245, 212, 0.6)',
            },
          }),
        },
      ],
    },
    MuiAppBar: {
      styleOverrides: {
        root: ({ theme }) => ({
          background: theme.palette.mode === 'dark'
            ? 'linear-gradient(90deg, #6B2E99, #1E4ED8)'
            : 'linear-gradient(90deg, #D8B4FE, #818CF8)', 
          padding: '10px 10px',
          height: '80px',
          display: 'flex',
          justifyContent: 'center',
          transition: 'background 0.3s ease',
          boxShadow: theme.palette.mode === 'dark'
            ? '0 4px 12px rgba(0, 0, 0, 0.5)'
            : '0 4px 12px rgba(0, 0, 0, 0.3)',
          '& .MuiToolbar-root': {
            color: '#FFFFFF',
          },
        }),
      },
      variants: [
        {
          props: { variant: 'gradient' },
          style: ({ theme }) => ({
            background: theme.palette.mode === 'dark'
              ? 'linear-gradient(90deg, #6B2E99, #1E4ED8)'
              : 'linear-gradient(90deg, #9D4EDD, #7B2CBF)',
          }),
        },
      ],
    },
    MuiPaper: {
      variants: [
        {
          props: { variant: 'section1' },
          style: ({ theme }) => ({
            width: '100%',
            padding: theme.spacing(6, 3),
            
            background: theme.palette.mode === 'dark'
              ? 'linear-gradient(135deg, #2A1B3D, #1A1025)' // Subtle dark gradient
              : 'linear-gradient(135deg, #FDF6FF, #F3E8FF)', // Soft morning purple
            boxShadow: theme.palette.mode === 'dark'
              ? '0 2px 8px rgba(0, 0, 0, 0.5)'
              : '0 2px 8px rgba(0, 0, 0, 0.5)',
            borderRadius: theme.spacing(1),
          }),
        },
        {
          props: { variant: 'section2' },
          style: ({ theme }) => ({
            width: '100%',
            padding: theme.spacing(6, 3),
            background: theme.palette.mode === 'dark'
              ? 'linear-gradient(135deg, #1A1025, #2A1B3D)' // Reversed dark gradient
              : 'linear-gradient(135deg, #F3E8FF, #FFE6FF)', // Warm day purple
            boxShadow: theme.palette.mode === 'dark'
              ? '0 2px 8px rgba(0, 0, 0, 0.5)'
              : '0 2px 8px rgba(0, 0, 0, 0.5)',
            borderRadius: theme.spacing(1),
          }),
        },
      ],
    },
    MuiCard: {
      variants: [
        {
          props: { variant: 'gradient' },
          style: ({ theme }) => ({
            background: theme.palette.mode === 'dark'
              ? 'linear-gradient(120deg, #5A189A, #1D3557)'  // Night sky gradient
              : 'linear-gradient(120deg, #D8B4FE, #818CF8)',  // Purple to indigo
            boxShadow: theme.palette.mode === 'dark'
              ? '0 4px 12px rgba(0, 0, 0, 0.8)'
              : '0 4px 12px rgba(0, 0, 0, 0.5)',
            borderRadius: theme.spacing(2),
            padding: theme.spacing(2),
            textAlign: 'center',
            transition: 'transform 0.3s ease, box-shadow 0.3s ease',
            '&:hover': {
              transform: 'scale(1.05)',
              boxShadow: theme.palette.mode === 'dark'
                ? '0 6px 18px rgba(0, 0, 0, 0.9)'
                : '0 6px 18px rgba(0, 0, 0, 0.6)',
            },
          }),
        },
      ],
    },

  },
};



const lightTheme = createTheme({
  ...commonSettings,
  palette: {
    mode: "light",
    primary: { main: "#1976d2", light: "#63a4ff", dark: "#004ba0", contrastText: "#ffffff" },
    secondary: { main: "#dc004e", light: "#ff5c8d", dark: "#9a0036", contrastText: "#ffffff" },
    error: { main: "#d32f2f", light: "#f44336", dark: "#9a0007", contrastText: "#ffffff" },
    warning: { main: "#ffa000", light: "#ffc107", dark: "#c67100", contrastText: "#ffffff" },
    info: { main: "#0288d1", light: "#03a9f4", dark: "#01579b", contrastText: "#ffffff" },
    success: { main: "#388e3c", light: "#4caf50", dark: "#00600f", contrastText: "#ffffff" },
    background: {
      default: "#f5f5f5", // Light grey
      paper: "#ffffff",   // White for cards and surfaces
      hover: "rgba(0, 0, 0, 0.04)", // Hover effect for light background
      focus: "rgba(0, 0, 0, 0.12)", // Focus effect for light background
    },
    text: {
      primary: "#000000", // Default text color
      secondary: "#555555", // Subtle text
      disabled: "rgba(0, 0, 0, 0.38)", // Disabled text
    },
    divider: "rgba(0, 0, 0, 0.12)", // Divider color
    action: {
      active: "#000000", // Active elements (e.g., icons)
      hover: "rgba(0, 0, 0, 0.04)", // Hover effect
      selected: "rgba(0, 0, 0, 0.08)", // Selected elements
      disabled: "rgba(0, 0, 0, 0.26)", // Disabled state
      disabledBackground: "rgba(0, 0, 0, 0.12)", // Disabled background
      focus: "rgba(0, 0, 0, 0.12)", // Focus effect
    },
  },
});

const darkTheme = createTheme({
  ...commonSettings,
  palette: {
    mode: "dark",
    primary: { main: "#90caf9", light: "#e3f2fd", dark: "#42a5f5", contrastText: "#000000" },
    secondary: { main: "#f48fb1", light: "#f8bbd0", dark: "#c2185b", contrastText: "#000000" },
    success: {
      main: "#81c784", // Brighter green
      light: "#a5d6a7",
      dark: "#388e3c",
      contrastText: "#000000", // Black text for readability
    },
    error: {
      main: "#ef5350", // Bright red
      light: "#e57373",
      dark: "#d32f2f",
      contrastText: "#ffffff", // White text for readability
    },
    warning: {
      main: "#ffa726", // Bright orange
      light: "#ffb74d",
      dark: "#f57c00",
      contrastText: "#000000",
    },
    info: {
      main: "#29b6f6", // Brighter blue
      light: "#4fc3f7",
      dark: "#0288d1",
      contrastText: "#000000",
    },
    background: {
      default: "#121212", // Main dark background
      paper: "#1e1e1e",   // Slightly lighter background for cards
      hover: "rgba(255, 255, 255, 0.08)", // Hover effect for dark background
      focus: "rgba(255, 255, 255, 0.12)", // Focus effect for dark background
    },
    text: {
      primary: "#ffffff", // Default text color
      secondary: "#bbbbbb", // Subtle text
      disabled: "rgba(255, 255, 255, 0.5)", // Disabled text
    },
    divider: "rgba(255, 255, 255, 0.12)", // Divider color
    action: {
      active: "#ffffff", // Active elements (e.g., icons)
      hover: "rgba(255, 255, 255, 0.1)", // Hover effect
      selected: "rgba(255, 255, 255, 0.2)", // Selected elements
      disabled: "rgba(255, 255, 255, 0.3)", // Disabled state
      disabledBackground: "rgba(255, 255, 255, 0.12)", // Disabled background
      focus: "rgba(255, 255, 255, 0.12)", // Focus effect
    },
  },
});

export { lightTheme, darkTheme };
