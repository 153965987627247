import { handleAPIError } from './ErrorHandler';


export const handleProcess = async ({
  selectedFile,
  setLoading,
  setError,
  setActiveStep,
  setUploadProgress,
  service,
  t,
  pollStatus,
  setProcessProgress,
  setSuccess,
  setDownloadProgress,
  setResultImage,
  api
}) => {
  if (!selectedFile) return;

  setLoading(true);
  setError('');

  try {
    // Upload
    const { data } = await api.uploadImage(selectedFile, setUploadProgress, service.url);
    setActiveStep(1);

    // Process
    await api.processImage(data.file_id, service.url);

    // Poll status and Download
    await pollStatus({
      fileId: data.file_id,
      service,
      setProcessProgress,
      setActiveStep,
      setLoading,
      setError,
      setSuccess,
      t,
      setDownloadProgress,
      setResultImage,
      api
    });

  } catch (error) {
    handleAPIError({
      error: error,
      setError: setError,
      setLoading: setLoading,
      setSuccess: setSuccess,
      translations: t
    });
  }
};

export const pollStatus = async ({
  fileId,
  service,
  setProcessProgress,
  setActiveStep,
  setLoading,
  setError,
  setSuccess,
  t,
  setDownloadProgress,
  setResultImage,
  api
}) => {
  const interval = setInterval(async () => {
    try {
      const { data } = await api.checkStatus(fileId, service.url);
      setProcessProgress(data.progress || null);
      if (data.status === 'completed') {
        clearInterval(interval);
        setActiveStep(2);
        const { data: imageBlob } = await api.downloadImageByID(fileId, setDownloadProgress, service.url);
        setResultImage(URL.createObjectURL(imageBlob));
        setActiveStep(3);
        setSuccess(t.processSuccess);
        setError('');
        setLoading(false);
      } else if (data.status === 'failed') {
        clearInterval(interval);
        throw new Error('Processing failed');
      }
    } catch (error) {
      clearInterval(interval);
      handleAPIError({
        error: error,
        setError: setError,
        setLoading: setLoading,
        setSuccess: setSuccess,
        translations: t
      });
    }
  }, 1000);

  return () => clearInterval(interval);
};

export const handleDownload = async ({
  resultImage,
  selectedFile,
  updateUserData,
  setError,
  setSuccess,
  t
}) => {
  try {
    // Download file
    const link = document.createElement('a');
    link.href = resultImage;
    link.download = `processed-${selectedFile.name}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // Update user data after successful download
    await updateUserData();
    setError('');
    setSuccess(t.downloadSuccess);
  } catch (error) {
    setError(t.downloadError);
    console.error('Download failed:', error);
  }
};

