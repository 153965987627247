import React, { useState } from 'react';
import { Box, Typography, Button, Alert, TextField, IconButton, InputAdornment } from '@mui/material';
import { useTranslations } from '../contexts/TranslationsContext';
import { useNavigate, useParams } from 'react-router-dom';
import { styled } from '@mui/system';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Container } from './ReusableComponents';

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(4),
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[5],
  maxWidth: 400,
  margin: 'auto',
  textAlign: 'center',
}));

const ResetButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  backgroundColor: theme.palette.primary.main,
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  width: '80%', // Make the input field smaller
}));

const ResetPassword = ({ language }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const { translations: t } = useTranslations();
  const { token } = useParams();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate(); // Define navigate

  const validatePassword = (password) => {
    const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return passwordRegex.test(password);
  };

  const handleResetPassword = async () => {
    let valid = true;

    if (!validatePassword(password)) {
      setPasswordError(t.passwordInvalid);
      valid = false;
    } else {
      setPasswordError('');
    }

    if (password !== confirmPassword) {
      setConfirmPasswordError(t.passwordMismatch);
      valid = false;
    } else {
      setConfirmPasswordError('');
    }

    if (!valid) {
      return;
    }

    try {
      const response = await fetch(`${apiUrl}/email/password/reset`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token, password }),
      });

      if (response.ok) {
        setMessage(t.passwordResetSuccess);
        setError('');
        setTimeout(() => {
          navigate('/login');  // Redirect to login page after 2 seconds
        }, 1000);
      } else {
        setError(t.passwordResetFailed);
        setMessage('');
      }
    } catch (error) {
      setError(t.passwordResetFailed);
      setMessage('');
    }
  };

  const toggleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword((show) => !show);
  };

  return (
    <Container>
      <StyledBox>
        <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', color: 'primary.main' }}>
          {t.resetPasswordTitle}
        </Typography>
        <StyledTextField
          label={t.newPassword}
          variant="outlined"
          type={showPassword ? 'text' : 'password'}
          margin="normal"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          error={!!passwordError}
          helperText={passwordError}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={toggleShowPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <StyledTextField
          label={t.confirmPassword}
          variant="outlined"
          type={showConfirmPassword ? 'text' : 'password'}
          margin="normal"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          error={!!confirmPasswordError}
          helperText={confirmPasswordError}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={toggleShowConfirmPassword}
                  edge="end"
                >
                  {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {message && <Alert severity="success">{message}</Alert>}
        {error && <Alert severity="error">{error}</Alert>}
        <ResetButton variant="contained" color="primary" onClick={handleResetPassword}>
          {t.resetPassword}
        </ResetButton>
      </StyledBox>
    </Container>
  );
};

export default ResetPassword;