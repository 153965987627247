// AboutUs.js
import React from 'react';
import { Box, Typography, Paper } from '@mui/material';
import { useTranslations } from '../contexts/TranslationsContext';
import { styled } from "@mui/system";
import aboutUsBg from './media/hero.webp'; // Add image import

const TitleBox = styled(Box)(({ theme }) => ({
  textAlign: "center",
  marginBottom: theme.spacing(4),
  position: "relative",
  zIndex: 1
}));

const AboutUs = () => {
  const { translations } = useTranslations();

  return (
    <Paper 
      variant="section1"
      sx={{
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        height: '90vh',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundImage: `url(${aboutUsBg})`,
          
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          zIndex: 0
        }
      }}
    >
      <Box sx={{ position: 'relative', zIndex: 2 }}>
        <TitleBox>
          <Box
            sx={{
              padding: 4,
              borderRadius: 2,
              backgroundColor: 'rgba(0, 0, 0, 0.4)',
              backdropFilter: 'blur(8px)',
              maxWidth: '800px',
              margin: '0 auto'
            }}
          >
            <Typography
              variant="h3"
              gutterBottom
              sx={{
                fontWeight: "bold",
                color: "#FFFFFF",
                fontSize: { xs: "2rem", sm: "2.5rem", md: "3rem" },
                textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)'
              }}
            >
              {translations.aboutUsTitle}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                color: "#FFFFFF",
                marginBottom: 2,
                fontSize: { xs: "0.9rem", sm: "1rem" },
                textShadow: '1px 1px 2px rgba(0, 0, 0, 0.3)'
              }}
            >
              {translations.aboutUsDescription}
            </Typography>
          </Box>
        </TitleBox>
      </Box>
    </Paper>
  );
};

export default AboutUs;
