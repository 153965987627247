import React, { useState, useContext } from 'react';
import { Drawer, Box, Button } from '@mui/material';
import LanguageSelector from './LanguageSelector';
import UserMenu from './UserMenu';
import { styled } from '@mui/system';
import { UserContext } from '../../contexts/UserContext';
import HeaderNavItem from './HeaderNavItem';
import ThemeToggle from './ThemeToggle';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import {
    UserInfo,
  } from './HeaderStyles';

const DrawerContent = styled(Box)(({ theme }) => ({
    width: '100&',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    padding: '1rem 0',
    gap: '100px', 
}));

const ButtonContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '10px', 
}));

// Main Component
const MobileSideBar = ({ drawerOpen, toggleDrawer }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const { user } = useContext(UserContext);

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const getInitial = (fullName) => {
        if (!fullName || fullName.startsWith('Guest')) return 'Guest'; // Default for guest users
        return fullName;
    };

    return (
        <Drawer
            anchor="top"
            open={drawerOpen} 
            onClose={toggleDrawer(false)}
            BackdropProps={{ invisible: true }}
            sx={{
                '& .MuiDrawer-paper': {
                    width: '100%',
                    height: 'auto',
                    top: '80px',
                    background: theme => theme.palette.mode === 'dark'
                        ? 'linear-gradient(90deg, #6B2E99, #1E4ED8)'  // Dark mode
                        : 'linear-gradient(90deg, #D8B4FE, #818CF8)',  // Light mode
                    transition: 'background 0.3s ease',
                    boxShadow: theme => theme.palette.mode === 'dark'
                        ? '0 4px 12px rgba(0, 0, 0, 0.5)'
                        : '0 4px 12px rgba(0, 0, 0, 0.3)',
                },
            }}
        >
            <DrawerContent>
                <HeaderNavItem/>

                <ButtonContainer>
                    <UserInfo>
                        <Button 
                            onClick={handleMenuOpen} 
                            variant='transparent'
                            startIcon={<AccountCircleIcon />}
                            endIcon={<ArrowDropDownIcon />}
                            sx={{ 
                                '& .MuiButton-endIcon': {
                                marginLeft: '-8px',
                                '& svg': {
                                    fontSize: '1.2rem', // Match parent font size
                                }
                            }
                            }}
                        >
                            {getInitial(user?.full_name)}
                        </Button>
                        <UserMenu
                            anchorEl={anchorEl}
                            handleMenuClose={handleMenuClose}
                        />
                    </UserInfo>
                    <LanguageSelector />
                    <ThemeToggle /> 
                </ButtonContainer>

            </DrawerContent>
        </Drawer>
    );
};

export default MobileSideBar;