export const updateUserData = async ({
    apiUrl,
    isAuthenticated,
    setUser,
    onError = console.error
  }) => {
    try {
      const endpoint = isAuthenticated ? 'user' : 'guest_user';
      const url = `${apiUrl}/transactions/${endpoint}`;
      
      const tokenType = isAuthenticated ? 'token' : 'guestToken';
      const currentToken = localStorage.getItem(tokenType);
  
      if (!currentToken) {
        throw new Error('No authentication token found');
      }
  
      const response = await fetch(url, {
        headers: {
          'Authorization': `Bearer ${currentToken}`,
        },
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
      setUser(data);
      return { success: true, data };
  
    } catch (error) {
      onError('Error updating user data:', error);
      return { success: false, error };
    }
  };