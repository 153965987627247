import React from 'react';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';

const SignupButton = ({ translations }) => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/signup');
    };

    return (
        <Button 
            variant='gradient'
            onClick={handleClick}
            startIcon={<PersonAddIcon />} // Add PersonAddIcon
        >
            {translations.signup}
        </Button>
    );
};

export default SignupButton;