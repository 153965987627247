import React, { useState, useContext } from 'react';
import { Toolbar, Button } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/system';
import { ServicesContext } from '../../contexts/ServicesContext';
import ServiceMenu from './ServiceMenu';
import { useTranslations } from '../../contexts/TranslationsContext';

const HeaderToolbar = styled(Toolbar)(({ theme }) => ({
  justifyContent: 'space-evenly',
  gap: '10px',
  flexDirection: 'row', // Default to row for wide devices
  [theme.breakpoints.down('md')]: {
      flexDirection: 'column', // Change to column for small devices
      alignItems: 'center', // Center items vertically
  },
}));

const HeaderButton = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  fontWeight: 'bold',
  fontSize: '16px',
  position: 'relative',
  color: theme.palette.text.primary,
  borderRadius: '8px',
  '&::after': {
      content: '""',
      position: 'absolute',
      left: 0,
      bottom: -2,
      width: '0%',
      height: '2px',
      backgroundColor: theme.palette.text.primary,
      transition: 'width 0.3s ease-in-out',
  },
  '&:hover::after': {
      width: '100%',
  },
  '& .MuiButton-startIcon': {
      margin: 0,
      '& svg': {
          fontSize: '1.5rem', // Match parent font size
      }
  },
  '& .MuiButton-endIcon': {
      marginLeft: '0px',
  },
}));

export default function HeaderNavItem({ isHeader = true }) {
  const navigate = useNavigate();
  const [anchorElServices, setAnchorElServices] = useState(null);
  const [anchorElAITools, setAnchorElAITools] = useState(null);
  const { AIService, services } = useContext(ServicesContext);
  const { translations } = useTranslations();

  const handleOpenServices = (event) => setAnchorElServices(event.currentTarget);
  const handleOpenAITools = (event) => setAnchorElAITools(event.currentTarget);
  const handleClose = () => {
    setAnchorElServices(null);
    setAnchorElAITools(null);
  };

  return (
      <HeaderToolbar>
        <HeaderButton
          color="inherit"
           onClick={() => navigate('/')}
           startIcon={<HomeIcon />} // HomeIcon as the start icon
        >
        </HeaderButton>

        <HeaderButton
          color="inherit"
          onClick={() => navigate('/')}
        >
          {translations.mission}
        </HeaderButton>

        {/* Services Dropdown */}
        <HeaderButton
          color="inherit"
          onClick={handleOpenServices}
          endIcon={anchorElServices ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          sx={{ 
            '& .MuiButton-endIcon': {
            marginLeft: 0,
            '& svg': {
                fontSize: '1.2rem', // Match parent font size
            }
          }
          }}
        >
          {translations.service}
        </HeaderButton>
        <ServiceMenu
          anchorEl={anchorElServices}
          handleClose={handleClose}
          items = {services}
          sx={{ 
            '& .MuiButton-endIcon': {
            marginLeft: 0,
            '& svg': {
                fontSize: '1.2rem', // Match parent font size
            }
          }
          }} 
          
        />

        {/* AI Tools Dropdown */}
        <HeaderButton
          color="inherit"
          onClick={handleOpenAITools}
          endIcon={anchorElAITools ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        >
          {translations.aiTools}
        </HeaderButton>

        <ServiceMenu
          anchorEl={anchorElAITools}
          handleClose={handleClose}
          items = {AIService}
        />

      </HeaderToolbar>
  );
}
