import React, { createContext, useState, useEffect, useContext } from 'react';
import { AuthContext } from './AuthContext';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const apiUrl = process.env.REACT_APP_API_URL;

    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    const { isAuthenticated, token, guestToken, loading: authLoading } = useContext(AuthContext);

    useEffect(() => {
        const fetchUserData = async () => {
            if (authLoading) {
                return;
            }

            try {
                const url = isAuthenticated ? `${apiUrl}/transactions/user` : `${apiUrl}/transactions/guest_user`;
                const currentToken = isAuthenticated ? token : guestToken;

                const response = await fetch(url, {
                    headers: {
                        'Authorization': `Bearer ${currentToken}`,
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    setUser(data);
                } else {
                    console.error('Failed to fetch user data');
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchUserData();
    }, [isAuthenticated, token, guestToken, apiUrl, authLoading]);

    return (
        <UserContext.Provider value={{ user, setUser, loading }}>
            {children}
        </UserContext.Provider>
    );
};