import React, { useState } from 'react';
import { AppBar, Toolbar, IconButton, Box } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close'; // Import CloseIcon
import { useNavigate } from 'react-router-dom';
import DesktopNav from './HeaderComponents/DesktopNav'; // Import DesktopNav
import MobileSideBar from './HeaderComponents/MobileSideBar'; // Import MobileSideBar
import { styled } from '@mui/system';
import junaiLogo from './media/junai_logo_white.svg';


const HeaderToolbar = styled(Toolbar)(({ theme }) => ({
    maxWidth: '1600px',
    width: '100%',
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
}));

export const Brand = styled(Box)(({ theme }) => ({
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(1),
    transition: 'all 0.3s ease',
    width: 'auto',
    '& img': {
      height: '60px',
      filter: theme.palette.mode === 'dark' ? 'brightness(1)' : 'brightness(0)',
      transition: 'all 0.3s ease',
    },
    '&:hover': {
      transform: 'scale(1.05)',
    },
  }));

const HamburgerMenu = styled(IconButton)(({ theme }) => ({
    color: theme.palette.text.primary,
    background: theme.palette.mode === 'dark'
              ? 'rgba(255, 255, 255, 0.1)'
              : 'rgba(255, 255, 255, 0.2)',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px', 
    transition: 'background 0.3s ease, box-shadow 0.3s ease', 
    '&:hover': {
        transform: 'scale(1.05)',
    },
}));


const Header = () => {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const navigate = useNavigate();

    const handleHamburgerMenuClick = async () => {
        setDrawerOpen(!drawerOpen); // Toggle the drawer
    };

    const toggleDrawer = (open) => () => {
        setDrawerOpen(open);
    };

    return (
        <AppBar 
            position="fixed" 
            sx={{ 
                top: 0,
                zIndex: (theme) => theme.zIndex.drawer + 1 
            }}
        >
            <HeaderToolbar>
                <Brand 
                    onClick={() => navigate('/')}
                    sx={{ display: { xs: 'flex', md: 'none' } }}
                >
                    <img src={junaiLogo} alt="Junai Logo" />
                </Brand>

                <DesktopNav />
                
                <HamburgerMenu
                    edge="end"
                    aria-label="menu"
                    onClick={handleHamburgerMenuClick}
                    sx={{ display: { xs: 'flex', md: 'none' } }}
                >
                    {drawerOpen ? <CloseIcon /> : <MenuIcon />}
                </HamburgerMenu>
                
                <MobileSideBar
                    drawerOpen={drawerOpen}
                    toggleDrawer={toggleDrawer}
                />
            </HeaderToolbar>
            
            
        </AppBar>
    );
};

export default Header;