import React, { useState, useContext } from 'react';
import { styled } from '@mui/system';
import LanguageSelector from './LanguageSelector';
import UserMenu from './UserMenu';
import HeaderNavItem from './HeaderNavItem';
import { UserContext } from '../../contexts/UserContext';
import ThemeToggle from './ThemeToggle';
import junaiLogo from '../media/junai_logo_white.svg';
import { useNavigate } from 'react-router-dom';
import { Brand } from '../Header';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Button } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import {
    HeaderDesktopNav,
    UserInfo,
} from './HeaderStyles';

const ButtonContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
}));

const DesktopNav = () => {
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const { user } = useContext(UserContext);

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const getInitial = (fullName) => {
        if (!fullName || fullName.startsWith('Guest')) return 'Guest'; // Default for guest users
        return fullName;
    };

    return (
        <>
            {/* Desktop Navigation */}
            <HeaderDesktopNav sx={{ display: { xs: 'none', md: 'flex' }}}>
                <Brand 
                    onClick={() => navigate('/')}
                    sx={{ display: { xs: 'none', md: 'flex' } }}
                >
                    <img src={junaiLogo} alt="Junai Logo" />
                </Brand>

                <HeaderNavItem/>

                 
                <ButtonContainer>
                    <UserInfo>
                        <Button 
                            onClick={handleMenuOpen} 
                            variant='transparent'
                            startIcon={<AccountCircleIcon />}
                            endIcon={<ArrowDropDownIcon />}
                            sx={{ 
                                '& .MuiButton-endIcon': {
                                marginLeft: '-8px',
                                '& svg': {
                                    fontSize: '1.2rem', // Match parent font size
                                }
                            }
                            }}
                        >
                            {getInitial(user?.full_name)}
                        </Button>
                        <UserMenu
                            anchorEl={anchorEl}
                            handleMenuClose={handleMenuClose}
                        />
                    </UserInfo>
                    <LanguageSelector />
                    <ThemeToggle /> 
                </ButtonContainer>

            </HeaderDesktopNav>
        </>
    );
};

export default DesktopNav;