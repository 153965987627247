import React from 'react';
import { Box, useTheme } from '@mui/material';
import { styled } from '@mui/system';
import ImageIcon from '@mui/icons-material/Image';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import DescriptionIcon from '@mui/icons-material/Description';
import AutorenewIcon from '@mui/icons-material/Autorenew'; // New icon for AI process
import MicIcon from '@mui/icons-material/Mic';

const IconContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '150px',
  borderRadius: '12px',
  boxShadow: theme.palette.mode === 'dark'
    ? '0 4px 12px rgba(0, 0, 0, 0.5)'
    : '0 4px 12px rgba(0, 0, 0, 0.1)',
  backgroundColor: theme.palette.mode === 'dark'
    ? 'rgba(26, 16, 37, 0.5)'
    : 'rgba(227, 187, 235, 0.1)',
    backdropFilter: 'blur(8px)',
}));

const iconMapping = {
  image: <ImageIcon style={{ fontSize: 100 }} />,
  text: <TextFieldsIcon style={{ fontSize: 100 }} />,
  photo: <PhotoLibraryIcon style={{ fontSize: 100 }} />,
  document: <DescriptionIcon style={{ fontSize: 100 }} />,
  audio: <MicIcon style={{ fontSize: 100 }} />,
};

const iconColors = {
  light: {
    primary: '#6B2E99', // Darker purple for light mode
    secondary: '#9D4EDD', // Lighter purple for light mode
  },
  dark: {
    primary: '#9D4EDD', // Lighter purple for dark mode
    secondary: '#D4A5FF', // Even lighter purple for dark mode
  },
};

const getServiceIcon = (serviceType, mode) => {
  const keywords = serviceType.split('-');
  const startIcon = React.cloneElement(iconMapping[keywords[0]] || <ImageIcon style={{ fontSize: 100 }} />, {
    sx: { color: iconColors[mode].primary },
  });
  const middleIcon = React.cloneElement(iconMapping[keywords[1]] || <AutorenewIcon style={{ fontSize: 60 }} />, {
    sx: { color: iconColors[mode].secondary },
  });
  const endIcon = React.cloneElement(iconMapping[keywords[keywords.length - 1]] || <ImageIcon style={{ fontSize: 100 }} />, {
    sx: { color: iconColors[mode].primary },
  });
  return { startIcon, middleIcon, endIcon };
};

const ToolIcon = ({ serviceType }) => {
  const theme = useTheme();
  const mode = theme.palette.mode;
  const { startIcon, middleIcon, endIcon } = getServiceIcon(serviceType, mode);
  return (
    <IconContainer>
      {startIcon}
      {middleIcon}
      {endIcon}
    </IconContainer>
  );
};

export default ToolIcon;